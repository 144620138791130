import api from "../../utils/api";
import { configJson } from "../firebaseUtils";
import authToken from "./authToken";

export function voucherLogAddProduct(uIdUrl, billCode, imagePath, vouchersIds) {
  return new Promise((resolve, reject) => {
    authToken(uIdUrl)
      .then((token) => {
        // console.log("USER: ", uIdUrl);
        api
          .post(
            `${configJson.API_HOST_NODE}/vouchers/log`,
            {
              billCode,
              billCodeImagePath: imagePath,
              partnerName: "IvoClar",
              logType: "registerVoucher",
              vouchersIds: vouchersIds,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log("RESULT_VOUCHER_LOG: ", result.data);
            return resolve(result.data.vouchers);
          })
          .catch((error) => {
            console.log("VOUCHER_LOG_ERROR: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}

export function voucherLogRequest(uId, nameDental, emailDental, storesNews, campaignId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        api
          .post(
            `${configJson.API_HOST_NODE}/vouchers/log`,
            {
              businessInfo: {
                name: nameDental,
                email: emailDental,
              },
              partnerName: "IvoClar",
              logType: "requestPrize",
              storesNews,
              campaignId
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log("***RESULT_VOUCHER_LOG: ", result.data);
            return resolve(result.data);
          })
          .catch((error) => {
            console.log("VOUCHER_LOG_ERROR: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}

export function voucherLogSendPrize(uIdDental, uIdWinner, storesIds) {
  // console.log("STORE_IDS", storesIds);

  return new Promise((resolve, reject) => {
    localStorage.clear("ivoclarToken")
    authToken(uIdDental)
      .then((token) => {
        api
          .post(
            `${configJson.API_HOST_NODE}/vouchers/log`,
            {
              partnerName: "IvoClar",
              logType: "sendPrize",
              prizeWinnerUid: uIdWinner,
              storesIds,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log("RESULT_VOUCHER_LOG: ", result.data);
            return resolve(result.data.vouchers);
          })
          .catch((error) => {
            // console.log("VOUCHER_LOG_ERROR: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}

export function getPrizesRequest(uId, emailDental, campaignId, logType) {
 
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        // console.log("USER: ", uIdUrl);
        api
          .post(
            `${configJson.API_HOST_NODE}/Ivoclar/getLogs`,
            {
              emailDental,
              logType,
              campaignId,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log("***RESULT_GET_PRIZES: ", result.data.response);
            return resolve(result.data.response);
          })
          .catch((error) => {
            console.log("VOUCHER_LOG_ERROR: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}

export function getPrizesRequestWithoutEmail(uId, campaignId, logType) {
 
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        // console.log("USER: ", uIdUrl);
        api
          .post(
            `${configJson.API_HOST_NODE}/Ivoclar/getLogs`,
            {
              logType,
              campaignId,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log("***RESULT_GET_PRIZES: ", result.data.response);
            return resolve(result.data.response);
          })
          .catch((error) => {
            console.log("VOUCHER_LOG_ERROR: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}
