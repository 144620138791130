import React from "react";
import { FiX } from "react-icons/fi";
import { configJson } from "../../services/firebaseUtils";
import { encrypt } from "../../utils/helpers";

import "./modalInformation.css";

const CRYPTO_KEY = configJson.CRYPTO_KEY;

function ModalInformation({
  close,
  setCouponNumbers,
  setImagePath,
  setNewImage,
  originUrl,
  setTaxNumber,
  cellPhone,
}) {
  function linkWebApp() {
    const storageUser = localStorage.getItem("IvoclarUser");
    let LINK_WEB_APP_LOGADO = "";

    if (storageUser) {

      // LINK PARA DIRECIONAR LOGADO WEB-APP
      LINK_WEB_APP_LOGADO = `${
        configJson.LINK_SITE_TRIIBO
      }/entrance/?userId=${encodeURIComponent(
        encrypt(cellPhone, CRYPTO_KEY)
      )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;
    } else {
      LINK_WEB_APP_LOGADO = "https://www.triibo.com.br/app/triibos/?id=-MVvXS7RrrbBFzW1NkPz";
    }
    window.location.href = LINK_WEB_APP_LOGADO;
    close();
  }

  return (
    <div className="modal-information">
      <div className="container-information">
        <div className="header-modal-information">
          <span>Produtos registrados com sucesso</span>
          <button
            className="close"
            onClick={() => {
              close();
              setCouponNumbers([""]);
              setTaxNumber("");
              setImagePath(null);
              setNewImage(null);
            }}
          >
            <FiX size={25} color={"#fff"} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <div className="text-information-content">
            <span>
              Parabéns, todos os cupons foram processados com sucesso. Em poucos
              minutos você poderá acessar sua carteira no site/app Triibo e
              acompanhar seu progresso!
            </span>
          </div>
            <button
              className="button-information"
              onClick={linkWebApp}
              type="button"
            >
              Concluir
            </button>
        </div>
      </div>
    </div>
  );
}

export default ModalInformation;
