import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { configJson } from "../../services/firebaseUtils";

import { cellPhoneMask, maskCnpj, cpfMask } from "../../utils/masks";
import ModalGivePrize from "../../components/ModalGivePrize";
import { getUserInfo_v1 } from "../../services/Apis/getUserInfo_v1";
import { getWallet_v1 } from "../../services/Apis/getWallet_v1";
import { getStorePlaces_v2 } from "../../services/Apis/getStorePlaces_v2";
import { getPrizesRequestWithoutEmail } from "../../services/Apis/voucherLog";
import getCampaign from "../../services/Apis/getCampaign";

// INPUT PESQUISA
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import { TextField } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress } from "@material-ui/core";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./giveprize.css";

const CustomTextField = styled(TextField)`
  label.Mui-focused {
    color: green;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: transparent;
      color: #000;
    }
    &:hover fieldset {
      border-color: transparent;
    }
    &.Mui-focused fieldset {
      border-color: transparent;
    }
  }
`;

const body = document.querySelector("body");

// GERA ARRAY DE BOOLEAN
const generateBooleanValue = (lenght) =>
  Array.from(Array(lenght).keys()).map(() => false);

const CAMPAIGN_ID = configJson.CAMPAIGN_ID;
const PartnerIdIvoclar = configJson.PARTNER_ID_IVOCLAR;

function GivePrize() {
  const [cellPhone, setCellPhone] = useState("");
  const [listProducts, setListProducts] = useState([]);
  const [prizeSelected, setPrizeSelected] = useState([]);
  const [copyPrizeSelected, setCopyPrizeSelected] = useState([]);
  const [amountCoupons, setAmountCoupons] = useState(0);
  const [amountCouponsUnsolicited, setAmountCouponsUnsolicited] = useState(0);
  const [totalPrize, setTotalPrize] = useState(0);

  const [dentalData, setDentalData] = useState(null);
  const [winnerData, setWinnerData] = useState(null);

  const [partnerDataWinner, setPartnerDataWinner] = useState(null);
  const [additionalInfoWinner, setAdditionalInfoWinner] = useState(null);

  const [vouchers, setVouchers] = useState([]);
  const [vouchersUnrequised, setVouchersUnrequised] = useState([]);

  const [allKeysVouchers, setAllKeyVouchers] = useState([]);

  const [apiVoucherKey, setApiVoucherKey] = useState([]);
  const [copyApiVoucherKey, setCopyApiVoucherKey] = useState([]);

  const [apiVoucherKeyUnrequised, setApiVoucherKeyUnrequised] = useState([]);
  const [copyApiVoucherUnrequised, setCopyApiVoucherUnrequised] = useState([]);

  const [templatId, setTemplateId] = useState([]);
  const [copyTemplatId, setCopyTemplatId] = useState([]);
  const [storesIds, setStoresIds] = useState([]);
  const [copyStoresIds, setCopyStoresIds] = useState([]);

  const [checked, setChecked] = useState([]);
  const [checkedUnsolicited, setCheckedUnsolicited] = useState([]);

  const [hasPrize, setHasPrize] = useState(false);
  const [messagePrize, setMessagePrize] = useState("");
  const [showModalInformation, setShowModalInforme] = useState(false);

  const [messageErrorCellPhone, setMessageErrorCellPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [triiboIdDental, setTriiboIdDental] = useState("");
  const [uIdDental, setUidDental] = useState("");
  const [uIdWinner, setUidWinner] = useState("");

  const [prizeText, setPrizeText] = useState("");

  // ESTADO DOS PRÊMIOS QUE AINDA NÃO FORAM SOLICITADOS
  const [storesIdsUnsolicited, setStoresIdsUnsolicited] = useState([]);
  const [copyStoresIdsUnsolicited, setCopyStoresIdsUnsolicited] = useState([]);
  const [templatIdUnsolicited, setTemplateIdUnsolicited] = useState([]);
  const [copyTemplatIdUnsolicited, setCopyTemplatIdUnsolicited] = useState([]);
  const [prizeSelectedUnsolicited, setPrizeSelectedUnsolicited] = useState([]);
  const [copyPrizeSelectedUnsolicited, setCopyPrizeSelectedUnsolicited] =
    useState([]);

  const [allStoreCosumerIdsConsume, setAllStoreIdsConsume] = useState([]);
  const [allTemplaIdsConsume, setAllTemplaIdsConsume] = useState([]);

  const history = useHistory();

  function handleForm() {
    setMessagePrize("");

    const claerArray = prizeSelected.filter(
      (prizeName) => prizeName !== "" && prizeName !== undefined
    );
    const clearArrayUnsolicited = prizeSelectedUnsolicited.filter(
      (item) => item !== "" && item !== undefined
    );

    const allProducts = claerArray.concat(clearArrayUnsolicited);
    const allkeyVouchers = apiVoucherKey.concat(apiVoucherKeyUnrequised);

    const allStoreIds = storesIds.concat(storesIdsUnsolicited);
    const allTemplateIds = templatId.concat(templatIdUnsolicited);

    const isValidPrizeSelected = allProducts.length !== 0;

    const keyVouchers = allkeyVouchers.filter(
      (item) => item !== "" && item !== undefined
    );

    setAllKeyVouchers(keyVouchers);

    if (isValidPrizeSelected) {
      let productNames = "";

      allProducts.forEach((item, index) => {
        productNames += `${item}${
          allProducts.length - 1 === index ? "." : ", "
        }`;
      });

      setPrizeText(productNames);
      setAllStoreIdsConsume(allStoreIds);
      setAllTemplaIdsConsume(allTemplateIds);
      setShowModalInforme(true);
    } else {
      setShowModalInforme(false);
      !isValidPrizeSelected && setMessagePrize("Selecione um produto");
    }
  }

  function handleSelectedPrize(product, indexInput, voucherKey) {
    apiVoucherKey[indexInput] = voucherKey;
    setApiVoucherKey([...apiVoucherKey]);
    setCopyApiVoucherKey([...apiVoucherKey]);

    storesIds[indexInput] = product.id;
    setStoresIds([...storesIds]);
    setCopyStoresIds([...storesIds]);

    templatId[indexInput] = product.promotion.templateId;
    setTemplateId([...templatId]);
    setCopyTemplatId([...templatId]);

    prizeSelected[indexInput] = product.promotion.title.trim();
    setPrizeSelected([...prizeSelected]);
    setCopyPrizeSelected([...prizeSelected]);
  }

  function handleSelectedPrizeUnsolicited(product, indexInput, voucherKey) {
    apiVoucherKeyUnrequised[indexInput] = voucherKey;
    setApiVoucherKeyUnrequised([...apiVoucherKeyUnrequised]);
    setCopyApiVoucherUnrequised([...apiVoucherKeyUnrequised]);

    storesIdsUnsolicited[indexInput] = product.id;
    setStoresIdsUnsolicited([...storesIdsUnsolicited]);
    setCopyStoresIdsUnsolicited([...storesIdsUnsolicited]);

    templatIdUnsolicited[indexInput] = product.promotion.templateId;
    setTemplateIdUnsolicited([...templatIdUnsolicited]);
    setCopyTemplatIdUnsolicited([...templatIdUnsolicited]);

    prizeSelectedUnsolicited[indexInput] = product.promotion.title.trim();
    setPrizeSelectedUnsolicited([...prizeSelectedUnsolicited]);
    setCopyPrizeSelectedUnsolicited([...prizeSelectedUnsolicited]);
  }

  function handleChecked(value, index) {
    setMessagePrize("");
    checked[index] = value;
    setChecked([...checked]);

    if (!value) {
      apiVoucherKey[index] = "";
      prizeSelected[index] = "";
      templatId[index] = "";
      storesIds[index] = "";
    }

    if (value && copyPrizeSelected.length !== 0) {
      apiVoucherKey[index] = "";
      apiVoucherKey[index] = copyApiVoucherKey[index];
      setApiVoucherKey([...apiVoucherKey]);

      storesIds[index] = "";
      storesIds[index] = copyStoresIds[index];
      setStoresIds([...storesIds]);

      templatId[index] = "";
      templatId[index] = copyTemplatId[index];
      setTemplateId([...templatId]);

      prizeSelected[index] = "";
      prizeSelected[index] = copyPrizeSelected[index];
      setPrizeSelected([...prizeSelected]);
    }
  }

  function handleCheckedUnsolicited(value, index) {
    setMessagePrize("");
    checkedUnsolicited[index] = value;
    setCheckedUnsolicited([...checkedUnsolicited]);

    if (!value) {
      apiVoucherKeyUnrequised[index] = "";
      prizeSelectedUnsolicited[index] = "";
      templatIdUnsolicited[index] = "";
      storesIdsUnsolicited[index] = "";
    }

    if (value && copyPrizeSelectedUnsolicited.length !== 0) {
      apiVoucherKeyUnrequised[index] = "";
      apiVoucherKeyUnrequised[index] = copyApiVoucherUnrequised[index];
      setApiVoucherKeyUnrequised([...apiVoucherKeyUnrequised]);

      storesIdsUnsolicited[index] = "";
      storesIdsUnsolicited[index] = copyStoresIdsUnsolicited[index];
      setStoresIdsUnsolicited([...storesIdsUnsolicited]);

      templatIdUnsolicited[index] = "";
      templatIdUnsolicited[index] = copyTemplatIdUnsolicited[index];
      setTemplateIdUnsolicited([...templatIdUnsolicited]);

      prizeSelectedUnsolicited[index] = "";
      prizeSelectedUnsolicited[index] = copyPrizeSelectedUnsolicited[index];
      setPrizeSelectedUnsolicited([...prizeSelectedUnsolicited]);
    }
  }

  function filteredStoreList(listPrize) {
    const listProductsFilter = listPrize.filter((store) => {
      if (
        store?.type === "promotion" &&
        store?.promotion?.hasOwnProperty("keyWordsSet")
      ) {
        return store;
      } else {
        return null;
      }
    });
    return listProductsFilter;
  }

  function checkWallet(walletReceived) {
    // PARÂMETROS PARA VERIFICAR O DIREITO AO PRÊMIO
    const establishmentId = configJson.ESTABLISHMENT_ID;
    const type = "store";
    const status = "enviado";

    const activeCoupons = [];
    const inactiveCoupons = [];

    // VERIFICA SE TEM CUPONS PARA RESGATE
    if (walletReceived !== null) {
      if (walletReceived.coupons !== null) {
        Object.keys(walletReceived.coupons).map(function (item, index) {
          let element = Object.keys(walletReceived.coupons)[index];
          walletReceived.coupons[item].key = element;
          if (
            walletReceived.coupons[item].establishmentId === establishmentId &&
            walletReceived.coupons[item].status === status &&
            walletReceived.coupons[item].type === type
          ) {
            return activeCoupons.push(walletReceived.coupons[item]);
          } else {
            return inactiveCoupons.push(walletReceived.coupons[item]);
          }
        });
      }
    }

    return {
      activeCoupons,
      inactiveCoupons,
    };
  }

  // BUSCA OS PRÊMIOS JÁ SOLICITADOS
  async function requestedPrizeSearch(uId) {
    try {
      const response = await getPrizesRequestWithoutEmail(
        uId,
        CAMPAIGN_ID,
        "requestPrize"
      );
      let prizesData = [];

      //MONTA O ARRAY PARA EXIBIR TODOS OS PRÉMIOS JÁ SOLICITADOS PELA DENTAL SELECIONADA.
      if (response.length > 0) {
        response.forEach((item) => {
          item.storesNews.forEach((product) => {
            let infoVoucher = { ...product, email: item.businessInfo.email };
            prizesData.push(infoVoucher);
          });
        });
      }

      return prizesData;
    } catch {
      console.log("ERRO_LOG_QUEST_PRIZE");
      setLoading(false);
      setMessageErrorCellPhone("Algo saiu errado, tente novamente mais tarde.");
    }
  }

  async function handleSubmit(event) {
    setMessageErrorCellPhone("");

    // APAGA O TOKEN COM O UID DO ULTIMO USUÁRIO PESQUISADO PARA EVITAR ERROS
    localStorage.removeItem("ivoclarToken");
    event.preventDefault();
    const isCellPhoneValid = cellPhone.length === 15;
    const triiboId = `+55${cellPhone.replace(/\D/g, "")}@sms,triibo,com,br`;
    const clearCellPhone = `+55${cellPhone.replace(/\D/g, "")}`;

    // TELEFONE GANHADOR
    const userInfo = {
      contactList: [
        {
          type: "cellPhone",
          value: clearCellPhone,
        },
      ],
    };

    // TELEFONE DENTAL
    const userInfoDental = {
      contactList: [
        {
          type: "cellPhone",
          value: triiboIdDental.slice(0, 14),
        },
      ],
    };

    if (isCellPhoneValid) {
      setLoading(true);
      try {
        const resultUser = await getUserInfo_v1(userInfo);
        const resultInfoDental = await getUserInfo_v1(userInfoDental);

        // VERIFICA SE O USUÁRIO EXISTE
        if (resultUser === null) {
          setLoading(false);
          setMessageErrorCellPhone(
            "Usuário não encontrado, revise o campo de telefone."
          );
          return;
        }

        // VERIFICA SE O USUÁRIO PARTICIPA DA CAMPANHA ATUAL.
        const resultCampaign = await getCampaign(
          resultUser.uId,
          CAMPAIGN_ID,
          "1"
        );
        if (!resultCampaign.data.status) {
          setLoading(false);
          setMessageErrorCellPhone("Usuário não participa da campanha.");
          return;
        }

        // PEGA O PARTNER DO GANHADOR
        resultUser?.partnerList.forEach((partner) => {
          if (partner.partnerId === PartnerIdIvoclar) {
            setPartnerDataWinner(partner);
          }
        });

        // PEGA O ADDITIONAL_INFO DO PARNER DO GANHADOR
        resultUser?.partnerList.forEach((partner) => {
          if (partner.partnerId === PartnerIdIvoclar) {
            partner.additionalInfo.forEach((additional) => {
              if (additional.campaignId === CAMPAIGN_ID) {
                setAdditionalInfoWinner(additional);
              }
            });
          }
        });

        setDentalData(resultInfoDental);
        setWinnerData(resultUser);
        setUidWinner(resultUser?.uId);

        // VERIFICA A CARTEIRA DO GANHADOR
        const walletReceived = await getWallet_v1(resultUser?.uId, triiboId);
        const { activeCoupons } = checkWallet(walletReceived);

        if (activeCoupons.length !== 0) {
          let emailDental = "";
          // PEGA O EMAIL DA DENTAL
          if (resultInfoDental?.partnerList) {
            const partnerList = resultInfoDental.partnerList;

            partnerList.forEach((partner) => {
              if (partner.partnerId === PartnerIdIvoclar) {
                partner.contactList.forEach((contact) => {
                  if (contact.type === "email") emailDental = contact.value;
                });
              }
            });
          }

          // VERIFICA QUAIS PRÊMIOS O USUÁRIO SOLICITOU PARA DENTAL
          const prizesRequest = await requestedPrizeSearch(resultUser?.uId);
          let prizesRequestActive = [];
          let prizesUnrequised = [];

          // VERIFICA SE O GANHADOR SOLICITOU ALGUM PRÊMIO
          if (prizesRequest.length > 0) {
            let listVouchers = activeCoupons;
            let dataIvoclar = prizesRequest;

            let listIvoclarCods = [];

            for (let i in dataIvoclar) {
              const log = dataIvoclar[i];

              listIvoclarCods.push(log.cod);
            }

            // REMOVE OS CUMPONS QUE JÁ FORAM SOLICITADOS
            for (let i in listVouchers) {
              const voucher = listVouchers[i];
              if (!listIvoclarCods.includes(voucher.key))
                prizesUnrequised.push(voucher);
            }

            prizesRequest.forEach((item) => {
              activeCoupons.forEach((counpon) => {
                // VERIFICA SE FOI SOLICITADO O PREMIO PARA A DENTAL QUE ESTA ENTREGANDO O PRÊMIO.
                if (
                  item?.email.toLowerCase().trim() ===
                    emailDental.toLowerCase().trim() &&
                  item.cod === counpon.key
                ) {
                  prizesRequestActive.push(counpon);
                }
              });
            });

            if (
              prizesRequestActive.length === 0 &&
              prizesUnrequised.length === 0
            ) {
              setMessageErrorCellPhone(
                "Usuário ainda não tem direito ao prêmio."
              );
              setLoading(false);
              return;
            }
          } else {
            prizesUnrequised = activeCoupons;
          }

          const listPrize = await getStorePlaces_v2(
            uIdDental,
            configJson.ESTABLISHMENT_ID
          );
          const listProductsFilter = filteredStoreList(listPrize);
          setAmountCoupons(prizesRequestActive.length);
          setAmountCouponsUnsolicited(prizesUnrequised.length);
          setTotalPrize(prizesRequestActive.length + prizesUnrequised.length);

          setVouchers(prizesRequestActive);
          setVouchersUnrequised(prizesUnrequised);

          setListProducts(listProductsFilter);
          setHasPrize(true);
        }

        setLoading(false);

        if (walletReceived.coupons === null || activeCoupons.length === 0) {
          setMessageErrorCellPhone("Usuário ainda não tem direito ao prêmio.");
          return;
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setMessageErrorCellPhone(
          "Algo saiu errado, tente novamente mais tarde."
        );
      }
    } else {
      setMessageErrorCellPhone(
        "Número de telefone inválido, digite um número com 9 digitos."
      );
    }
  }

  useEffect(() => {
    setChecked([...generateBooleanValue(amountCoupons)]);
    setCheckedUnsolicited([...generateBooleanValue(amountCouponsUnsolicited)]);
  }, [amountCoupons, amountCouponsUnsolicited]);

  //BUSCA DADOS DA URL
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userIdDental = urlParams.get("userId");
    const triiboIdDentalUrl = urlParams.get("triiboId");

    const isValidParamUidDental =
      userIdDental === "" ||
      userIdDental === undefined ||
      userIdDental === null;

    const isValidParamTriiboIdDental =
      triiboIdDentalUrl === "" ||
      triiboIdDentalUrl === undefined ||
      triiboIdDentalUrl === null;

    if (isValidParamUidDental && isValidParamTriiboIdDental) {
      history.push("/");
      return;
    }

    const clearTriiboIdUrl = `+${triiboIdDentalUrl
      .trim()
      .replace(/[,]/gi, ".")}`;
    setTriiboIdDental(clearTriiboIdUrl);
    setUidDental(userIdDental);
  }, [history]);

  useEffect(() => {
    setPrizeSelected([]);
    setCopyPrizeSelected([]);

    setTemplateId([]);
    setCopyTemplatId([]);

    setStoresIds([]);
    setCopyStoresIds([]);

    setShowModalInforme(false);
    setMessagePrize("");
    setHasPrize(false);
    setLoading(false);
    setMessageErrorCellPhone("");
    body.style.backgroundColor = "#10233f";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  function getDocumentWinner(type) {
    let document = "";

    additionalInfoWinner.documentList.forEach((item) => {
      if (item.type === type) {
        document = item.value;
      }
    });

    type === "cnpj"
      ? (document = maskCnpj(document))
      : (document = cpfMask(document));

    return document;
  }

  return (
    <>
      <Header isShowLinks={false} />
      <div
        style={{ height: `${!hasPrize ? "100vh" : "100%"}` }}
        className="container-give-prize"
      >
        <ModalGivePrize
          close={setShowModalInforme}
          open={showModalInformation}
          cellPhone={cellPhone}
          product={prizeText}
          itemId={allKeysVouchers}
          templatId={allTemplaIdsConsume}
          storesIds={allStoreCosumerIdsConsume}
          triiboIdDental={triiboIdDental}
          uIdDental={uIdDental}
          uIdWinner={uIdWinner}
          dataInfo={{ dental: dentalData, winner: winnerData }}
        />
        <form
          onSubmit={handleSubmit}
          onChange={() => setMessageErrorCellPhone("")}
          className="form-give-prize"
        >
          {!hasPrize ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {!hasPrize && (
                <div style={{ textAlign: "center", marginBottom: "50px" }}>
                  <h1 className="title-give-prize">Entrega de prêmio</h1>
                  <p>Digite abaixo o telefone do ganhador do prêmio.</p>
                </div>
              )}
              <div className="area-input-give-prize">
                {messageErrorCellPhone && (
                  <label className="text-error-give-prize">
                    {messageErrorCellPhone}
                  </label>
                )}
                <label className="label-input-give">Telefone:*</label>
                <input
                  className="container-give-prize-input"
                  type="tel"
                  name="cellphone"
                  placeholder="(00) 00000-0000"
                  autoComplete="off"
                  maxLength="15"
                  value={cellPhone}
                  onChange={(e) => setCellPhone(cellPhoneMask(e.target.value))}
                />

                <button
                  disabled={loading}
                  className="button-give-prize"
                  type="submit"
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    "Pesquisar"
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className="area-form-give-prize-02">
              <h1 style={{ marginTop: "20%" }}>Entregar prêmio</h1>
              <p> O cliente tem direito a {totalPrize} IPS e.max </p>
              <div className="content-contact-client">
                <div className="item-contact-client">
                  <span>
                    <strong className="bold-label-give-prize">
                      Nome do operador:{" "}
                    </strong>
                    {partnerDataWinner.name} {partnerDataWinner.lastName}
                  </span>
                  <span>
                    <strong className="bold-label-give-prize">
                      Nome do estabelecimento:{" "}
                    </strong>
                    {additionalInfoWinner.entityType === "Laboratório (CNPJ)"
                      ? additionalInfoWinner.corporateName
                      : `${additionalInfoWinner.name} ${additionalInfoWinner.lastName}`}
                  </span>
                </div>

                <div className="item-contact-client">
                  <span>
                    <strong className="bold-label-give-prize">
                      Telefone do operador:{" "}
                    </strong>
                    {cellPhone}
                  </span>
                  {additionalInfoWinner.entityType === "Laboratório (CNPJ)" ? (
                    <span>
                      <strong className="bold-label-give-prize">CNPJ: </strong>
                      {getDocumentWinner("cnpj")}
                    </span>
                  ) : (
                    <span>
                      <strong className="bold-label-give-prize">CPF: </strong>
                      {getDocumentWinner("cpf")}
                    </span>
                  )}
                </div>
              </div>

              <h2 style={{ marginTop: "30px" }}>Solicitado para sua Dental:</h2>

              {messagePrize !== "" && (
                <span style={{ color: "red", fontSize: "13px" }}>
                  {messagePrize}
                </span>
              )}

              {vouchers.length === 0 ? (
                <div className="area-text-info-gize-prize">
                  <p>Nenhum prêmio foi solicitado para a sua dental.</p>
                </div>
              ) : (
                vouchers.map((coupon, inputIndex) => {
                  return (
                    <div
                      className="container-preview-prizes--give-prive"
                      key={inputIndex}
                    >
                      <div
                        key={inputIndex}
                        className="area-selected-give-prize"
                      >
                        {/* INPUT DE PESQUISA */}
                        <div className="content-input-cod-give-prize">
                          <label className="label-preview--give-prize">
                            {`Cupom: ${inputIndex + 1}`}{" "}
                          </label>
                          <input
                            value={coupon.key}
                            style={{ alignSelf: "flex-start" }}
                            type="text"
                            disabled
                            className="input-code-number-give-prize"
                          />
                        </div>

                        <div className="select-prize--give-prize">
                          <label className="label-preview--request-prize">
                            Produto{" "}
                          </label>
                          <Autocomplete
                            id="field_0"
                            options={listProducts}
                            disabled={!checked[inputIndex]}
                            noOptionsText="Produto não encontrado"
                            disableClearable={true}
                            getOptionLabel={(option) => option.promotion.title}
                            onChange={(_, newValue) => {
                              if (newValue !== null) {
                                handleSelectedPrize(
                                  newValue,
                                  inputIndex,
                                  coupon.key
                                );
                              }
                            }}
                            renderOption={(option) => option.promotion.title}
                            className="select-prize--give-prize"
                            renderInput={(params) => (
                              <CustomTextField
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: "8px",
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                                variant="outlined"
                                placeholder="Pesquisar produto"
                                size="small"
                                autoComplete="off"
                                autoCorrect="off"
                                id="field1"
                              />
                            )}
                          />
                        </div>

                        <div className="content-checkbox-area-give-prize">
                          <Checkbox
                            checked={checked[inputIndex]}
                            onChange={() => {
                              handleChecked(!checked[inputIndex], inputIndex);
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            style={{ color: "#42baef" }}
                          />
                          <span className="checkbox-text-prize">
                            Entregar agora
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}

              {vouchersUnrequised.length > 0 && (
                <h2 style={{ marginTop: "30px" }}>
                  Cliente ainda não pediu resgate:{" "}
                </h2>
              )}
              {vouchersUnrequised.length > 0 &&
                vouchersUnrequised.map((coupon, inputIndex) => {
                  return (
                    <div
                      className="container-preview-prizes--give-prive"
                      key={inputIndex}
                    >
                      <div
                        key={inputIndex}
                        className="area-selected-give-prize"
                      >
                        {/* INPUT DE PESQUISA */}
                        <div className="content-input-cod-give-prize">
                          <label className="label-preview--request-prize">
                            {`Cupom: ${inputIndex + 1}`}{" "}
                          </label>
                          <input
                            value={coupon.key}
                            style={{ alignSelf: "flex-start" }}
                            type="text"
                            disabled
                            className="input-code-number-give-prize"
                          />
                        </div>

                        <div className="select-prize--give-prize">
                          <label className="label-preview--request-prize">
                            Produto{" "}
                          </label>
                          <Autocomplete
                            id="field_0"
                            options={listProducts}
                            disabled={!checkedUnsolicited[inputIndex]}
                            noOptionsText="Produto não encontrado"
                            disableClearable={true}
                            getOptionLabel={(option) => option.promotion.title}
                            onChange={(_, newValue) => {
                              if (newValue !== null) {
                                handleSelectedPrizeUnsolicited(
                                  newValue,
                                  inputIndex,
                                  coupon.key
                                );
                              }
                            }}
                            renderOption={(option) => option.promotion.title}
                            className="select-prize--give-prize"
                            renderInput={(params) => (
                              <CustomTextField
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: "8px",
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                                variant="outlined"
                                placeholder="Pesquisar produto"
                                size="small"
                                autoComplete="off"
                                autoCorrect="off"
                                id="field1"
                              />
                            )}
                          />
                        </div>

                        <div className="content-checkbox-area-give-prize">
                          <Checkbox
                            checked={checkedUnsolicited[inputIndex]}
                            onChange={() => {
                              handleCheckedUnsolicited(
                                !checkedUnsolicited[inputIndex],
                                inputIndex
                              );
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            style={{ color: "#42baef" }}
                          />
                          <span className="checkbox-text-prize">
                            Entregar agora
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className="area-text-info-gize-prize">
                {/* <p>Texto de aviso para a dental.</p> */}
              </div>

              <button
                className="button-give-prize"
                onClick={handleForm}
                type="button"
              >
                Confirmar prêmio
              </button>
            </div>
          )}
        </form>
        {!hasPrize && <Footer />}
      </div>
      {hasPrize && <Footer />}
    </>
  );
}

export default GivePrize;
