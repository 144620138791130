import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { checkDevice } from "../../utils/helpers";

import Banner_desktop from "../../assets/images/Banner-Promoção-Encerrada.jpg";
import Banner_mobile from "../../assets/images/banner_encerrado_mobile-80.jpg";

import add_product_01 from "../../assets/images/add_product-stap_01.png";
import add_product_02 from "../../assets/images/add_product-stap_02.png";
import add_product_03 from "../../assets/images/add_product-stap_03.png";
import add_product_04 from "../../assets/images/image-nota.png";
import add_product_05 from "../../assets/images/add_product-stap_05.png";
import add_product_06 from "../../assets/images/add_product-stap_06.png";

import request_prize_02 from "../../assets/images/request_prize-stap_02.png";
import request_prize_03 from "../../assets/images/request_prize-stap_03.png";
import request_prize_04 from "../../assets/images/request_prize-stap_04.png";
import request_prize_05 from "../../assets/images/request_prize-stap_05.png";

import "./tutorial-style.css";

export default function Tutorial() {
  const [isMobile, setIsMobile] = useState(false);
  const [isShowTutorialOne, setIsShowTutorialOne] = useState(false);
  const [isShowTutorialTwo, setIsShowTutorialTwo] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMobile(checkDevice());
    setIsShowTutorialOne(false);
    setIsShowTutorialTwo(false);
  }, []);

  return (
    <>
      <Header isTutorial={true} />
      <div className="container-tutorial">
        <div className="banner-tutorial-content">
          <img src={isMobile ? Banner_mobile : Banner_desktop} alt="Banner" />
        </div>

        <h1>Siga o passo a passo de como cadastrar seus produtos</h1>
        {/* LINHA DE DIVISÃO */}

        <div className="content-tutotial-add">
          {isShowTutorialOne && (
            <>
              <div className="item-tutoria ">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">1</strong>
                  </div>
                  <span>Acesse a área logada.</span>
                </div>
                <img src={add_product_01} alt="Passo 1" />
              </div>

              <div className="item-tutoria">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">2</strong>
                  </div>
                  <span>
                    Encontre a área <b>registre suas compras</b>.
                  </span>
                </div>
                <img src={add_product_02} alt="Passo 2" />
              </div>

              <div className="item-tutoria">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">3</strong>
                  </div>
                  <span>
                    Adicione o número da <b>Nota Fiscal</b> e os números <br />
                    dos cupons que você recebeu pelas Dentais.
                  </span>
                </div>
                <img src={add_product_03} alt="Passo 3" />
              </div>

              <div className="item-tutoria">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">3.1</strong>
                  </div>
                  <span>
                    Caso não saiba onde encontra o número da nota <br />
                    veja a imagem a baixo.
                  </span>
                </div>
                <img src={add_product_04} alt="Passo 4" />
              </div>

              <div className="item-tutoria">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">3.2</strong>
                  </div>
                  <span>
                    Para adicionar mais códigos de cupons, basta clicar
                    <br />
                    no botão: <b>Adicionar mais cupons</b>.
                  </span>
                </div>
                <img src={add_product_05} alt="Passo 5" />
              </div>

              <div className="item-tutoria">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">4</strong>
                  </div>
                  <span>
                    Após registrar todos os códigos, selecione a opção <br />
                    <b>registar compra</b>.
                  </span>
                </div>
                <img src={add_product_06} alt="Passo 6" />
              </div>
            </>
          )}
          <span
            className="button-open-tutorial"
            onClick={() => setIsShowTutorialOne(!isShowTutorialOne)}
          >
            {isShowTutorialOne
              ? "Clique aqui para fechar tutorial"
              : "Clique aqui para ver o tutorial"}
          </span>
        </div>

        <h1>
          Alcançou 10 prêmios? <strong>Parabéns!</strong>
          {<br />} Saiba como solicitar seu prêmio.
        </h1>

        <div className="content-tutotial-request">
          {isShowTutorialTwo && (
            <>
              <div className="item-tutorial-request">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">1</strong>
                  </div>
                  <div className="textCircle">
                    <span>Acesse a área logada.</span>
                  </div>
                </div>

                <img src={add_product_01} alt="passo 01" />
              </div>

              <div className="item-tutorial-request">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">2</strong>
                  </div>
                  <div className="textCircle">
                    <span>
                      Encontre a área <b>solicite seu prêmio</b>.
                    </span>
                  </div>
                </div>
                <img src={request_prize_02} alt="passo 02" />
              </div>

              <div className="item-tutorial-request">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">3</strong>
                  </div>
                  <div className="textCircle">
                    <span>
                      Informe a dental de preferência e o prêmio desejado <br />
                      e selecione os itens disponíveis para o resgate.
                    </span>
                  </div>
                </div>
                <img src={request_prize_03} alt="passo 03" />
              </div>

              <div className="item-tutorial-request">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">4</strong>
                  </div>
                  <div className="textCircle">
                    <span>Confirme as informações para finalizar.</span>
                  </div>
                </div>
                <img src={request_prize_04} alt="passo 04" />
              </div>

              <div className="item-tutorial-request">
                <div className="item-circle">
                  <div className="border-gradient">
                    <strong className="areaCircle">5</strong>
                  </div>
                  <div className="textCircle">
                    <span>
                      Adicionalmente você pode acompanhar seu histórico
                      <br />
                      na área abaixo. Assim que a dental entregar o prêmio
                      <br />
                      para você, o mesmo não aparecerá mais neste campo.
                    </span>
                  </div>
                </div>
                <img src={request_prize_05} alt="passo 05" />
              </div>
            </>
          )}
          <span
            className="button-open-tutorial"
            onClick={() => setIsShowTutorialTwo(!isShowTutorialTwo)}
          >
            {" "}
            {isShowTutorialTwo
              ? "Clique aqui para fechar tutorial"
              : "Clique aqui para ver o tutorial"}
          </span>
        </div>

        <div className="section-stapTwo">
          <h4>
            Qualquer dúvida, você pode entrar em contato pelo número (11)
            98548-1546.
          </h4>
        </div>

        <Footer />
      </div>
    </>
  );
}
