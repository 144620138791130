import React, { useEffect, useContext } from "react";

import { UserContext } from "../../contexts/user";
import { configJson } from "../../services/firebaseUtils";
import { encrypt } from "../../utils/helpers";

import { useHistory } from "react-router-dom";

import "./information.css";

import arrowNextImg from "../../assets/images/right-arrow.svg";
import arrowPrevImg from "../../assets/images/left-arrow.svg";
import loja_android from "../../assets/images/loja-android.png";
import loja_ios from "../../assets/images/loja-ios.png";
import SlideInformation from "../../components/SlideInformation";

const LINK_LOJA_ANDROID = "https://triibo.page.link/1c84";
const LINK_LOJA_IOS = "https://triibo.page.link/TWAF";

const CRYPTO_KEY = configJson.CRYPTO_KEY;

function Information() {
  const { cellPhone } = useContext(UserContext);
  const history = useHistory();

  // PASSAR PARA LOGAR
  const LINK_WEB_APP_LOGADO = `${
    configJson.LINK_SITE_TRIIBO
  }/entrance/?userId=${encodeURIComponent(
    encrypt(cellPhone, CRYPTO_KEY)
  )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // LIMPA O LOCAL_STORAGE
  useEffect(() => {
    localStorage.clear();
  }, []);

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    if(!cellPhone) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-information">
      <main className="main-infomation">
        <h1 className="title-information">
          O seu cadastro foi concluído com sucesso!
        </h1>
        <p>
          Ao clicar no botão "Entrar", você será direcionado para a Triibo. Onde
          poderá registrar suas compras, visualizar seus registros, resgatar
          seus prêmios, ter acesso a todas as informações da campanha! E não
          para por ai, você encontrará vários descontos e outras campanhas para
          partipar e concorrer a mais prêmios.
        </p>
        <p>
          Para melhorar sua experiência, baixe o app para seu celular e tenha
          acesso a todos os benefícios na palma da sua mão!
        </p>
        <h1 className="title-info">Como cadastrar os produtos?</h1>
        {/* LINHA DE DIVISÃO */}

        <div className="lineDivider" />
        <div className="content-buttons-carousel">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
          className="btn-control-carousel"
        >
          <img className="prev-carousel" src={arrowPrevImg} alt="" />
        </button>
         {/* CARROSSEL */}
         <SlideInformation />
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
          className="btn-control-carousel"
        >
          <img className="next-carousel" src={arrowNextImg} alt="" />
        </button>
      </div>
       

        <div
          className="button-login-link"
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "480px",
            alignSelf: "center",
            marginTop: "50px"          
          }}
        >
          <a
            title="Triibo"
            rel="noreferrer"
            href={LINK_WEB_APP_LOGADO}
            style={{ color: "#fff" }}
          >
            Continuar
          </a>
        </div>
        <p className="text-botton">
          Para entrar pelo aplicativo, lembre-se de informar o celular
          cadastrado que você usa no acesso.
        </p>
        <div className="img-area">
          <a
            title="android"
            target="_blank"
            rel="noreferrer"
            href={LINK_LOJA_ANDROID}
          >
            <img src={loja_android} alt="loja android" />
          </a>
          <a title="ios" target="_blank" rel="noreferrer" href={LINK_LOJA_IOS}>
            <img src={loja_ios} alt="loja ios" />
          </a>
        </div>
      </main>
    </div>
  );
}

export default Information;
