import React, { useState, useEffect, useContext } from "react";
import { cpfMask } from "../../utils/masks";
import { SignUpFormContext } from "../../contexts/signUpForm";
import { configJson } from "../../services/firebaseUtils";

import { useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import Footer from "../../components/Footer";
import Header from "../../components/Header"

import { ValidateCpf } from "../../utils/helpers";
import { UserContext } from "../../contexts/user";

import ModalSignUp from "../../components/ModalSignUp";
import "./signUp.css";

const body = document.querySelector("body");
const optionRegister = [
  "Profissional (CPF)",
  "Laboratório (CNPJ)",
];

const optionRole = ["Assistente TPD", "Assistente Dentista", "TPD", "Dentista"];

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 10,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: "#fff",
    },
    backgroundColor: "#fff",
  },
}))(InputBase);

function SignUp() {

  // PEGA OS DADOS DO CONTEXT
  const {
    setCpfUserContext,
    setNameUser,
    setLastNameUser,
    setEmailUser,
    setUserRole,
    setEntityType,
  } = useContext(SignUpFormContext);

  const { operatorCpf } = useContext(UserContext);

  const localStorageUserData = JSON.parse(localStorage.getItem("IvoclarUser"));
  const PartnerIdIvoclar = configJson.PARTNER_ID_IVOCLAR;
  let hasIvoclar = false;

  if (localStorageUserData?.partnerList) {
    const partnerIvoclar = localStorageUserData.partnerList.filter(item => item.partnerId === PartnerIdIvoclar)

    hasIvoclar = partnerIvoclar.length > 0 ? true : false;
  }

  const [showModalLogin, setShowModalLogin] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Profissional (CPF)");
  const [selectedRole, setSelectedRole] = useState("Prestador");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cpfUser, setCpfUser] = useState(operatorCpf ? operatorCpf : "");
  const [email, setEmail] = useState("");
  const [emailChecked, setEmailChecked] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [nextPage, setNextPage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [messageErroCpf, setMessageErroCpf] = useState("");

  const [modalType, setModalType] = useState("cpf");

  const history = useHistory();

  const formData = {
    name: `${name.trim()} ${lastName.trim()}`,
    lastName: lastName.trim(),
    cpf: cpfUser.replace(/\D/g, ""),
    aliasName: name.trim(),
    email: email,
  };

  function toggleModalLogin() {
    setShowModalLogin(!showModalLogin);

    //QUANDO O MODAL ESTIVER ABERTO EVITA SCROLAR O CONTEÚDO DA PÁGINA
    body.style.overflow = showModalLogin ? "" : "hidden";
  }

  function storageRole() {
    const data = {
      selectedItem,
      selectedRole,
    };

    localStorage.setItem("UserRole", JSON.stringify(data));
  }

  function handleSubmit(event) {
    event.preventDefault();
    storageRole();
    // CASO O EMAIL DIGITADO NÃO SEJAM IGUAIS RETORNA ERRO
    if (email !== emailChecked) {
      setIsEmailValid(true);
      return;
    }

    // DE ACORDO COM O QUE FOI SELECIONADO VAI PARA PÁGINAS DIFERENTE
    if (selectedItem.includes("Profissional (CPF)")) {
      setNextPage("/cadastrar-dentista");
    } else {
      setNextPage("/cadastrar-laboratorio");
    }

    // SALVA OS DADOS NO CONTEXT
    setCpfUserContext(cpfUser.replace(/\D/g, ""));
    setNameUser(name);
    setLastNameUser(lastName);
    setEmailUser(email);
    setEntityType(selectedItem);
    setUserRole(selectedRole);

    if (ValidateCpf(cpfUser.replace(/\D/g, ""))) {
      toggleModalLogin();
    } else {
      setMessageErroCpf(
        "CPF inválido. Revise o campo de CPF e tente novamente."
      );
    }
  }

  function handleSubmit02(event) {
    event.preventDefault();
    storageRole();


    // DE ACORDO COM O QUE FOI SELECIONADO VAI PARA PÁGINAS DIFERENTE
    if (selectedItem.includes("Profissional (CPF)")) {
      setNextPage("/cadastrar-dentista");
    } else {
      setNextPage("/cadastrar-laboratorio");
    }

    // SALVA OS DADOS NO CONTEXT
    setEntityType(selectedItem);
    setUserRole(selectedRole);

    toggleModalLogin();
    
  }

  useEffect(() => {
    body.style.backgroundColor = "#10233f";
    body.style.overflow = "";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsEmailValid(false);
  }, [email, emailChecked]);

  //VALIDA O FORMULÁRIO
  useEffect(() => {
    if (
      name !== "" &&
      lastName !== "" &&
      cpfUser !== "" &&
      email !== "" &&
      emailChecked !== ""
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, lastName, cpfUser, email, emailChecked]);

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    const userLocalStorage = JSON.parse(localStorage.getItem("IvoclarUser"));

    if (userLocalStorage === null) {
      history.push("/");
    }
  }, [history]);

  return (
    <>
    <Header isShowLinks={false}/>
    <div className="container-signUp">
      <h1>Cadastro de acesso</h1>

      <p>
        As informações a seguir{" "}
        <strong style={{ textDecoration: "underline" }}>
          serão utilizadas para o acesso a conta e cadastro
        </strong>{" "}
        dos produtos, e não necessariamente precisam ser do
        profissional/estabelecimento.
      </p>

      <form onSubmit={handleSubmit} className="main-content">
        {
          hasIvoclar ? (
            <div className="area-input" style={{ maxWidth: "350px" }}>

              <label>O que você está cadastrando?</label>
              <NativeSelect
                id="item"
                value={selectedItem}
                onChange={(e) => {
                  setSelectedItem(e.target.value);
                  if (e.target.value === "Laboratório (CNPJ)") {
                    setModalType("cnpj");
                  } else {
                    setModalType("cpf");
                  }
                }}
                input={<BootstrapInput />}
              >
                {optionRegister.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </NativeSelect>

              <label style={{ marginTop: "9px" }}>Qual a sua função?</label>
              <NativeSelect
                id="role"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                input={<BootstrapInput />}
              >
                {optionRole.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </NativeSelect>

              <button
                type="submit"
                onClick={handleSubmit02}
              >
                Próximo
              </button>
            </div>
          ) :
          (
            <>
            <div className="area-input">
              <label>O que você está cadastrando?</label>
              <NativeSelect
                id="item"
                value={selectedItem}
                onChange={(e) => {
                  setSelectedItem(e.target.value);
                  if (e.target.value === "Laboratório (CNPJ)") {
                    setModalType("cnpj");
                  } else {
                    setModalType("cpf");
                  }
                }}
                input={<BootstrapInput />}
              >
                {optionRegister.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </NativeSelect>
              <label style={{ marginTop: "9px" }}>Qual a sua função?</label>
              <NativeSelect
                id="role"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                input={<BootstrapInput />}
              >
                {optionRole.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </NativeSelect>
              <label style={{ marginTop: "9px" }}>Nome:</label>
              <input
                type="text"
                name="name"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label>Sobrenome:*</label>
              <input
                type="text"
                name="lastName"
                placeholder="Sobrenome"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="area-input">
              {messageErroCpf && (
                <span style={{ color: "red", fontSize: "13px" }}>
                  {messageErroCpf}
                </span>
              )}
              <label>CPF:*</label>
              <input
                type="text"
                name="cpfUser"
                placeholder="000.000.000-00"
                maxLength="14"
                value={cpfMask(cpfUser)}
                onChange={(e) => {
                  setCpfUser(e.target.value);
                  setMessageErroCpf("");
                }}
              />
              {isEmailValid && (
                <label style={{ color: "red", fontSize: "13px" }}>
                  Digite o mesmo email para prosseguir.
                </label>
              )}
              <label>Email:*</label>
              <input
                style={{ border: `${isEmailValid ? "1px solid red" : "0px"}` }}
                type="email"
                name="email"
                placeholder="email@exemplo.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Confirme seu email:*</label>
              <input
                style={{ border: `${isEmailValid ? "1px solid red" : "0px"}` }}
                type="email"
                name="email_checked"
                placeholder="email@exemplo.com"
                value={emailChecked}
                onChange={(e) => setEmailChecked(e.target.value)}
              />

              <button
                disabled={!isFormValid}
                style={{
                  backgroundColor: `${isFormValid ? "#42baef" : "#b6e1f3"}`,
                }}
                type="submit"
                onClick={handleSubmit}
              >
                Próximo
              </button>
            </div>
            </>
          )
        }
      </form>

      {/* MODAL LOGIN */}
      {showModalLogin && (
        <ModalSignUp
          close={toggleModalLogin}
          nextPage={nextPage}
          data={formData}
          modalType={modalType}
          hasIvoclar={hasIvoclar}
        />
      )}

      <Footer /> 

    </div>
    </>
  );
}

export default SignUp;
