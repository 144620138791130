import axios from "axios";
import { getChannelToken_v1 } from "../Apis/getChannelToken_v1";
import { configJson } from "../firebaseUtils";

export function getStorePlaces_v2(uId, establishmentId) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1(uId)
      .then((resultTriiboHeader) => {
        axios
          .post(configJson.API_HOST_V1 + "getStorePlaces_v2", {
            triiboHeader: resultTriiboHeader,
            objectType: ["promotion"],
            order: true,
            geoFocus: {
              lat: null,
              long: null,
            },
            flagGoogle: false,
            geoRadius: 10000,
            platform: "web",
            from: 0,
            size: 500,
            userQuery: null,
            establishmentId: establishmentId,
            systemQuery: null,
          })
          .then((result) => {
            // console.log("RESULT_STORE_PLACES ===>", result);
            resolve(result.data.success.list);
          })
          .catch((error) => {
            console.log("ERROR STORE PLACES ===>", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("ERROR_GET_CHANNEL_TOKEN ===>", error);
        reject(error);
      });
  });
}
