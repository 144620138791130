import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import SlideDoubts from "../../components/SlideDoubts";
import ModalListDentais from "../../components/ModalListDentais";
import ModalLogin from "../../components/ModalLogin";
import { checkDevice } from "../../utils/helpers";

import { useHistory } from "react-router-dom";

import bannerDesktop from "../../assets/images/Banner-Promoção-Encerrada.jpg";
import bannerMobile from "../../assets/images/banner_encerrado_mobile-80.jpg";

import triiboLogo from "../../assets/images/triiboLg.png";
import android from "../../assets/images/triiboLojaAndroid.png";
import ios from "../../assets/images/triiboLojaIos.png";

import Footer from "../../components/Footer";

import "./home.css";

// PDF DE REGULAMENTO DA PROMOÇÃO FICA TRIIBO-HOMOL/FIREBASE/STORAGE/IVOCLAR
const LINK_PDF =
  "https://firebasestorage.googleapis.com/v0/b/project-4616920480246266429.appspot.com/o/ivoclar%2FRegulamento_v4.pdf?alt=media&token=88b6c432-2511-4228-bb9b-0743d0f41dd4";

const STEP_BY_STEP = [
  { number: "1", text: "Compre seus tubos de IPS e.max." },
  { number: "2", text: "Realize sua inscrição." },
  { number: "3", text: "Cadastre seus produtos aqui." },
  { number: "4", text: "A cada 10 tubos de IPS e.max você ganha +1." },
  {
    number: "5",
    text: "Escolha a dental de preferência e solicite seu prêmio.",
  },
];

const LINK_LOJA_ANDROID = "https://triibo.page.link/1c84";
const LINK_LOJA_IOS = "https://triibo.page.link/TWAF";

const body = document.querySelector("body");

function Home() {
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [nextPage, setNextPage] = useState("");
  const history = useHistory();

  function toggleModalLogin() {
    setShowModalLogin(!showModalLogin);
  }

  useEffect(() => {
    // QUANDO O MODAL ESTIVER ABERTO EVITA SCROLAR O CONTEÚDO DA PÁGINA
    body.style.overflow = showModalLogin ? "hidden" : "";
  }, [showModalLogin]);

  useEffect(() => {
    // LIMPA O LOCAL_STORAGE
    localStorage.clear();
    setIsMobile(checkDevice());
    window.history.pushState("", "", "/");
  }, []);

  return (
    <>
      <Header
        setShowModalLogin={setShowModalLogin}
        setNextPage={setNextPage}
        isShowLinks={true}
      />
      <div className="container-home" style={{ overflow: "hidden" }}>
        <div className="image_content">
          <img
            src={isMobile ? bannerMobile : bannerDesktop}
            alt="Imagens promoção"
          />
        </div>

        <div id="promocao" className="titleOffert">
          <span>Compre IPS e.max e ganhe mais da original!</span>
        </div>

        <div className="subtitle-home">
          <span>Como participar</span>
        </div>

        <div className="container-box">
          <div className="content-boxTurorial">
            <div className={"boxTutorialDarkBlue"}>
              <h1 className={"numberStepDark"}>{STEP_BY_STEP[0].number}</h1>
              <div className="textStep">
                <span>{STEP_BY_STEP[0].text}</span>
              </div>
            </div>
          </div>

          <div className="content-boxTurorial">
            <div className={"boxTutorialDarkBlue"}>
              <h1 className={"numberStepDark"}>{STEP_BY_STEP[1].number}</h1>
              <div className="textStep">
                <span>{STEP_BY_STEP[1].text}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-box">
          <div className="content-boxTurorial">
            <div className={"boxTutorialDarkBlue"}>
              <h1 className={"numberStepDark"}>{STEP_BY_STEP[2].number}</h1>
              <div className="textStep">
                <span>{STEP_BY_STEP[2].text}</span>
              </div>
            </div>
          </div>

          <div className="content-boxTurorial">
            <div className={"boxTutorialDarkBlue"}>
              <h1 className={"numberStepDark"}>{STEP_BY_STEP[3].number}</h1>
              <div className="textStep">
                <span>{STEP_BY_STEP[3].text}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="content-boxTurorial" style={{ maxWidth: "500px" }}>
          <div className={"boxTutorialDarkBlue"}>
            <h1 className={"numberStepDark"}>{STEP_BY_STEP[4].number}</h1>
            <div className="textStep">
              <span>{STEP_BY_STEP[4].text}</span>
            </div>
          </div>
        </div>

        <div className="timeOffert">
          <span>
            O período de participação é do dia 09/05/2022 até o dia 30/11/2022.
          </span>
        </div>

        <div className="moreOffert">
          <span>
            Quer saber mais? Veja o{" "}
            <a
              className="btn-pdf"
              title="Regulamento"
              target="_blank"
              rel="noreferrer"
              href={LINK_PDF}
            >
              NOSSO REGULAMENTO
            </a>
            .
          </span>
        </div>

        {/* TUTORIAL */}
        <div className="subtitle-home">
          <span>Veja nosso tutorial</span>
        </div>
        <div className="timeOffert">
          <span>
            Selecione o botão abaixo para visualizar como cadastrar seus
            produtos e solicitar o seu prêmio.
          </span>
        </div>

        <button
          className="button-dentais"
          onClick={() => history.push("/tutorial")}
        >
          Ver agora
        </button>

        <div className="subtitle-home">
          <span>Dúvidas frequentes</span>
        </div>

        {/* CARROSSEL DE DÚVIDAS */}
        <SlideDoubts />

        {/* DENTAIS PARTICIPANTES */}
        <br />
        <div className="subtitle-home">
          <span>Dentais participantes</span>
        </div>
        <div className="timeOffert">
          <span>
            Clique abaixo para ver todas as Dentais participantes da campanha.
          </span>
        </div>

        {/* MODAL LISTA DE DENTAIS */}
        <ModalListDentais />

        {/* CADASTRO OU LOGIN */}
        <section className="button-area">
          <div className="content-button-home">
            <div className="area-login-home">
              <span>Já está cadastrado na campanha?</span>
              <button
                style={{ cursor: "not-allowed", opacity: "0.7" }}
                disabled
                onClick={() => {
                  toggleModalLogin();
                  setNextPage("triibo-web");
                }}
              >
                Entrar
              </button>
            </div>

            <div className="area-login-home">
              <span>Inscreva-se na Campanha!</span>
              <button
                style={{ cursor: "not-allowed", opacity: "0.7" }}
                disabled
                onClick={() => {
                  toggleModalLogin();
                  setNextPage("/cadastrar");
                }}
              >
                Inscreva-se
              </button>
            </div>
          </div>
        </section>

        <Footer />

        {/* BAIXE O APP TRIIBO */}
        <div id="app" className="app-triibo">
          <div className="are-app-triibo">
            <img src={triiboLogo} alt="logo triibo" />
            <div className="area-text-app">
              <p>Baixe o app e aproveite mais experiencias como essa!</p>
            </div>
            <div>
              <a
                title="android"
                target="_blank"
                rel="noreferrer"
                href={LINK_LOJA_ANDROID}
              >
                <img src={android} alt="Android" />
              </a>
              <a
                title="ios"
                target="_blank"
                rel="noreferrer"
                href={LINK_LOJA_IOS}
              >
                <img src={ios} alt="Apple" />
              </a>
            </div>
          </div>
        </div>

        {/* MODAL LOGIN */}
        {showModalLogin && (
          <ModalLogin
            close={toggleModalLogin}
            setNextPage={setNextPage}
            nextPage={nextPage}
          />
        )}
      </div>
    </>
  );
}

export default Home;
