import React, { useEffect, useState, useContext } from "react";
import { FiX } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";
import { maskCnpj, cpfMask } from "../../utils/masks";
import { ValidateCpf } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import { configJson } from "../../services/firebaseUtils";
import { setUserInfo_v1 } from "../../services/Apis/setUserInfo_v1";

import { UserContext } from "../../contexts/user";

import "./modalSignUp.css";

const ORG_ID_IVOCLAR = configJson.ORG_ID_IVOCLAR;
const PartnerIdIvoclar = configJson.PARTNER_ID_IVOCLAR;

function ModalSignUp({ close, nextPage, data, modalType, hasIvoclar }) {
  const {
    loading,
    setLoading,
    setMessageErroCode,
    message,
    setMessage,
    cellPhone,
  } = useContext(UserContext);

  const localStorageUserData = JSON.parse(localStorage.getItem("IvoclarUser"));

  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [messageErrorCpf, setMessageErrorCpf] = useState("");

  const history = useHistory();

  // RESETA A PÁGINA
  useEffect(() => {
    setMessage("");
    setMessageErrorCpf("");
    setLoading(false);
  }, [
    setMessage,
    setMessageErrorCpf,
    setLoading,
  ]);

  async function checkUser(event) {
    event.preventDefault();
    setMessageErrorCpf("");
    setMessage("");
    setLoading(true);

    // VERIFICA CPF
    if (modalType === "cpf" && !ValidateCpf(cpf.replace(/\D/g, ""))) {
      setLoading(false);
      return setMessageErrorCpf(
        "CPF inválido. Revise o campo de CPF e tente novamente."
      );
    }

    // VERIFICA CPF
    if (modalType === "cpf") {
      if (!ValidateCpf(cpf.replace(/\D/g, ""))) {
        setLoading(false);
        return setMessageErrorCpf(
          "CPF inválido. Revise o campo de CPF e tente novamente."
        );
      }
    }

    let hasDocument = false;
    let partnerDataIvolcar = null;

    // PROCURAR O CPF OU CNPJ 
    if (localStorageUserData?.partnerList) {
      localStorageUserData?.partnerList.forEach(({ partnerId, documentList}, position) => {
        let documentValue = modalType === "cpf" ? cpf.replace(/\D/g, "") : cnpj.replace(/\D/g, "");

        if (partnerId === PartnerIdIvoclar) {
          partnerDataIvolcar = localStorageUserData?.partnerList[position];
          
          documentList.forEach((_, index) => {

            if (documentList[index].type === modalType) {
              documentList[index].value === documentValue ? hasDocument = true : hasDocument = false;

            }
            
          })
        }
      });
    }
    
    localStorage.setItem("ivoclar_ProficionalData", 
      modalType === "cpf" ? 
      JSON.stringify({cpf: cpf.replace(/\D/g, ""), dataPartnerList: hasDocument ? partnerDataIvolcar : null}) :
      JSON.stringify({cnpj: cnpj.replace(/\D/g, ""), dataPartnerList: hasDocument ? partnerDataIvolcar : null})
    );

    localStorage.setItem("ivoclar_alreadyParticipated", JSON.stringify({alreadyParticipated : hasDocument ? true : false}));

    addUserData();
  }


  //ADICIONA DADOS NA TABELA USERS-V3
  async function addUserData() {
    const user = localStorageUserData ? localStorageUserData : null;

    if (!user) {
      setMessage("Erro ao cadastrar dados.");
      return;
    }

    const plantnerName = "ivoclar";
    const newKeyWordsSetLimit = [plantnerName];
    const newKeyWordsSetCustom = [plantnerName];
    const newOrgs = [ORG_ID_IVOCLAR];
    const orgsUser = localStorageUserData.orgs;
    const uIdUser = localStorageUserData.uId

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.keyWordsSetLimit) &&
      user?.keyWordsSetLimit.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetLimit.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.keyWordsSetCustom) &&
      user?.keyWordsSetCustom.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetCustom.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM ORGS
    Array.isArray(orgsUser) &&
      orgsUser.forEach((org) => {
        org !== ORG_ID_IVOCLAR && newOrgs.push(org);
      });

    const currentDate = new Date().getTime();
    //INFORMAÇÕES PARA CHAMAR A API SET_USER_INFO
    const userData = {
      authenticationMethodList: [
        {
          cellPhone: cellPhone,
          dateVerified: currentDate,
          enabled: true,
          lastlogin: currentDate,
          type: "SMS - Triibo",
          verified: true,
        },
      ],
      contactList: [
        {
          type: "cellPhone",
          value: cellPhone,
        },
        {
          type: "email",
          value: data?.email,
        },
      ],
      documentList: [
        {
          type: "cpf",
          value: data?.cpf,
        },
      ],
      keyWordsSetLimit: [...newKeyWordsSetLimit],
      keyWordsSetCustom: [...newKeyWordsSetCustom],
      orgs: [...newOrgs],
      name: data?.name,
      aliasName: data?.aliasName,
      updateDate: currentDate,
    };    

    if (hasIvoclar) {
      history.push({pathname: nextPage, state: data});
      return;
    }

    // API QUE ADICIONA DADOS NO USUÁRIO
    setUserInfo_v1(uIdUser, userData)
      .then((result) => {
        setLoading(false);
        history.push({pathname: nextPage, state: data});
      })
      .catch((error) => {
        console.log("CONTEXT_USER_ERROR: ", error);
        setLoading(false);
        setMessage("Algo saiu errado, tente novamente mais tarde.");
        setMessageErroCode("Algo saiu errado, tente novamente mais tarde.");
      });
  }

  return (
    <div className="modal-login">
      <div className="container-login">
        <div className="header-modal-login">
          <span>Informe o {modalType === "cpf" ? "CPF" : "CNPJ"}</span>
          <button className="close" onClick={close}>
            <FiX size={25} color={"#fff"} />
          </button>
        </div>

        <form className="form-login" onSubmit={checkUser} >
          <>
            {/* OBS: Remover o css inline */}
            <span style={{ color: "#696969", fontSize: "17px", marginBottom: "0.8rem", marginTop: "30px" }} >
              Digite o {modalType === "cpf" ? "CPF" : "CNPJ"} que deseja inscrever na campanha
            </span>
            {messageErrorCpf && (
              <label style={{ color: "red" }}>{messageErrorCpf}</label>
            )}
            {message && <label style={{ color: "red" }}>{message}</label>}
            {
              modalType === "cpf" ? (
                <>
                <label className="label-input-modal-login">CPF:</label>
                <input
                  value={cpf}
                  onChange={(event) => {
                    setCpf(cpfMask(event.target.value));
                    setMessageErrorCpf("");
                    setMessage("");
                  }}
                  autoFocus
                  type="tel"
                  name="cpf"
                  placeholder="000.000.000-00"
                  maxLength="14"
                  autoComplete="off"
                />
                </>
              ) :
              (
                <>
                  <label>CNPJ:</label>
                  <input
                    value={cnpj}
                    onChange={(event) => {
                      setCnpj(maskCnpj(event.target.value))
                      setMessage("");
                    }}
                    autoFocus
                    type="tel"
                    name="cnpj"
                    placeholder="00.000.000/0000-00"
                    maxLength="18"
                    autoComplete="off"
                  />
                </>
              )
            }
            { modalType === "cpf" ? (
                <button
                  className="button-login"
                  disabled={cpf.replace(/\D/g, "").length !== 11 || loading}
                  type="submit"
                  style={{
                    cursor: `${
                      cpf.replace(/\D/g, "").length !== 11
                        ? "no-drop"
                        : "pointer"
                    }`,
                  }}
                  onClick={checkUser}
                >
                 {loading ? (
                   <CircularProgress size={20} style={{ color: "#fff" }} />
                 ) : (
                   "Próximo"
                 )}
               </button>
              ) : (
                <button
                  className="button-login"
                  disabled={cnpj.replace(/\D/g, "").length !== 14 || loading}
                  type="submit"
                  style={{
                    cursor: `${
                      cnpj.replace(/\D/g, "").length !== 14
                        ? "no-drop"
                        : "pointer"
                    }`,
                  }}
                  onClick={checkUser}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  "Próximo"
                )}
              </button>
            )}
          </>
        </form>
      </div>
    </div>
  );
}

export default ModalSignUp;
