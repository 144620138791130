import axios from "axios";
import { configJson } from "../firebaseUtils";
import { getChannelToken_v1UidNull } from "../Apis/getChannelToken_v1UidNull";
// eslint-disable-next-line
import { loginUser } from "../../services/AuthenticationManager";
// import { pushDatabase_v1 } from "js/library/services/DatabaseManager.js";

export function validateSMS_v1(code, cellPhone, transactionId) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1UidNull()
      .then((resultTriiboHeader) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);
        axios
          .post(configJson.API_HOST + "validateSMS_v1", {
            triiboHeader: resultTriiboHeader,
            transactionId: transactionId,
            cellphone: cellPhone,
            code: code,
          })
          .then((result) => {
            // console.log("RESULT_VALIDATE_SMS ===>", result);

            if (result.data.success !== null) {
              resolve(result);
            } else {
              reject({ messageError: result.data.error.error });
            }
          })
          .catch((error) => {
            console.log("ERROR VALIDATE SMS ===>", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("ERROR GET CHANNEL TOKEN ===>", error);
        reject(error);
      });
  });
}
