import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { cpfMask, maskCep, cellPhoneMask, phoneMask } from "../../utils/masks";
import { ValidateCpf } from "../../utils/helpers";

import { SignUpFormContext } from "../../contexts/signUpForm";
import { UserContext } from "../../contexts/user";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { configJson } from "../../services/firebaseUtils";

import "./customersInfo.css";

const body = document.querySelector("body");

const PartnerIdIvoclar = configJson.PARTNER_ID_IVOCLAR;
const CAMPAIGN_ID = configJson.CAMPAIGN_ID;

const LINK_PDF =
  "https://firebasestorage.googleapis.com/v0/b/triibo-homol.appspot.com/o/ivoclar%2FRegulamentoV1_IPS_emax.pdf?alt=media&token=225830b5-83e8-48b4-8a4f-22e20e1ecb4d";
const LINK_PRIVACY_POLICY =
  "https://www.ivoclarvivadent.com/en_US/Company/About-Us";

function CustomersInfo() {
  // PEGA OS DADOS DO CONTEXT
  const { userRole, entityType } = useContext(SignUpFormContext);
  const { user } = useContext(UserContext);

  const dataPartner = JSON.parse(localStorage.getItem("ivoclar_ProficionalData"));
  const partnerLocal = dataPartner?.dataPartnerList;

  // DADOS ANTERIORES DO PARTNER
  useEffect(() => {
    if (partnerLocal) {
      // CONTACT_LIST
      if (partnerLocal.contactList.length > 0) {
        partnerLocal.contactList.forEach(contact => {
          if (contact.type === "cellPhone") {
            setCellPhone(cellPhoneMask(contact.value.replace("+55", "").replace(/\D/g, ""))) 
          }
  
          if (contact.type === "landline") {
            setPhoneNumber(phoneMask(contact.value.replace("+55", "").replace(/\D/g, ""))) 
          }
  
          if (contact.type === "email") {
            setEmail(contact.value) 
          }
        })
      } 

      // DOCUMENT_LIST
      if (partnerLocal.documentList.length > 0) {
        partnerLocal.documentList.forEach(document => {
          if (document.type === "cro") {
            setCro(document.value)
          }
  
        })
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [cpf, setCpf] = useState(dataPartner?.cpf ? dataPartner?.cpf : "");
  const [name, setName] = useState(partnerLocal ? partnerLocal.name : "");
  const [lastName, setLastName] = useState(partnerLocal ? partnerLocal.lastName : "");
  const [cellPhone, setCellPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [cro, setCro] = useState("");
  const [country, setCountry] = useState(partnerLocal ? partnerLocal.addressList[0].country : "");
  const [cep, setCep] = useState(partnerLocal ? partnerLocal.addressList[0].zipCode : "");
  const [state, setState] = useState(partnerLocal ? partnerLocal.addressList[0].state : "");
  const [city, setCity] = useState(partnerLocal ? partnerLocal.addressList[0].city : "");
  const [street, setStreet] = useState(partnerLocal ? partnerLocal.addressList[0].street : "");
  const [number, setNumber] = useState(partnerLocal ? partnerLocal.addressList[0].number : "");
  const [aditionalInformation, setAditionalInformation] = useState(partnerLocal ? partnerLocal.addressList[0].extra : "");
  const [dataFormSignUp, setDataFromSignUp] = useState("");
  const [messageErrorCpf, setMessageErrorCpf] = useState("");
  const [messageErrorApi, setMessageErrorApi] = useState("");
  const [messageErrorCep, setMessageErrorCep] = useState("");
  const [messageErrorTerms, setMessageErrorTerms] = useState("");

  const [hasEmptyInput, setHasEmptyInput] = useState(false);

  const [isNotification, setIsNotification] = useState(true);
  const [isRegulation, setIsRegulation] = useState(true);
  const [isTerms, setIsTerms] = useState(true);

  // DADOS DO FORMULÁRIO ANTERIOR
  const location = useLocation();
  const history = useHistory();

  // PEGA DADOS DO LOCAL_STORAGE
  useEffect(() => {
    function loadingStorage() {
      const storageUser = localStorage.getItem("UserRole");

      if (storageUser) {
        setDataFromSignUp(JSON.parse(storageUser));
      }
    }

    loadingStorage();
  }, []);

  useEffect(() => {
    if (
      number !== "" &&
      cpf !== "" &&
      name !== "" &&
      lastName !== "" &&
      cro !== "" &&
      cellPhone !== "" &&
      phoneNumber !== "" &&
      email !== "" &&
      cep !== "" &&
      country !== "" &&
      state !== "" &&
      city !== "" &&
      street !== ""
    ) {
      setHasEmptyInput(true);
    } else {
      setHasEmptyInput(false);
    }
  }, [
    number,
    cpf,
    name,
    lastName,
    cro,
    cellPhone,
    phoneNumber,
    email,
    cep,
    country,
    state,
    city,
    street,
  ]);

  async function handleSubmit(event) {
    event.preventDefault();

    // VERIFICA OS TERMOS
    if (!isTerms) {
      setMessageErrorTerms("Aceite as Políticas de Privacidade para continuar.");
      return;
    }

    if (!isRegulation) {
      setMessageErrorTerms("Aceite o Regulamento da promoção para continuar.");
      return;
    }
      
    // VERIFICA CPF
    if (!ValidateCpf(cpf.replace(/\D/g, ""))) {
      return setMessageErrorCpf(
        "CPF inválido. Revise o campo de CPF e tente novamente."
      );
    }

    const oldValuePartnerList = [];

    // VERIFICA SE O USUÁRIO TEM OUTROS PARTNER_LIST
    if (user?.partnerList) {
      const partnerlist = user?.partnerList;

      partnerlist.forEach((partner) => {
        if (partner.partnerId !== PartnerIdIvoclar) {
          oldValuePartnerList.push(partner);
        }
      });
    }

    const dataPartner = {
      partnerList:
        {
          documentList: [
            {
              type: "cpf",
              value: cpf.replace(/\D/g, ""),
            },
            {
              type: "cro",
              value: cro,
            },
          ],
          name: name.trim(),
          lastName: lastName.trim(),
          addressList: [
            {
              state: state,
              city: city,
              country: country,
              number: number,
              extra: aditionalInformation,
              zipCode: cep.replace(/\D/g, ""),
              street: street,
              type: "comercial",
            },
          ],
          contactList: [
            {
              type: "email",
              value: email,
            },
            {
              type: "cellPhone",
              value: `+55${cellPhone.replace(/\D/g, "")}`,
            },
            {
              type: "landline",
              value: `+55${phoneNumber.replace(/\D/g, "")}`,
            },
          ],
          entityType: entityType ? entityType : dataFormSignUp?.selectedItem,
          campaignId: CAMPAIGN_ID,
          optInList: [
            {
              type: "Termos de Uso - Ivoclar - Regulamento da campanha",
              optInId: "-TermoDeUsoIvoclar-01",
              version: 1.0,
              dateAcceptance: Date.now(),
              accept: isRegulation,
            },
          ],
        },
    };

    const dataFormOperator = location.state;

    // DADOS DO PARTNER, CASO O USUÁRIO TENHA OUTROS PARTNER MANTEM OS ANTIGOS E ADICIONA O NOVO.
    const dataOperator = {
      partnerList: [
        ...oldValuePartnerList.map((partners) => partners),
        {
          documentList: [
            {
              type: "cpf",
              value: dataFormOperator.cpf.replace(/\D/g, ""),
            },
          ],
          contactList: [
            {
              type: "email",
              value: dataFormOperator.email,
            },
          ],
          name: dataFormOperator.aliasName,
          lastName: dataFormOperator.lastName,
          userRole: userRole ? userRole : dataFormSignUp?.selectedRole,
          partnerName: "IvoClar",
          partnerId: PartnerIdIvoclar,
          optInList: [
            {
              type: "Termos de Uso - Ivoclar - Recebimento de e-mails",
              optInId: "-TermoDeUsoIvoclar-02",
              version: 1.0,
              dateAcceptance: Date.now(),
              accept: isNotification,
            },
            {
              type: "Termos de Uso - Ivoclar - Política de privacidade",
              optInId: "-TermoDeUsoIvoclar-03",
              version: 1.0,
              dateAcceptance: Date.now(),
              accept: isTerms,
            },
          ],
        }  
      ]
    }

    // ARMAZENA OS DADOS DO FORMULÁRIO DO LOCAL_STORAGE, PARA PREENCHER OS CAMPOS CASO O USUÁRIO VOLTE NA TELA 
    localStorage.setItem("ivoclar_ProficionalData", JSON.stringify({cpf: cpf, dataPartnerList: dataPartner.partnerList}));
    
    const uId = user?.uId;

    history.push({
      pathname: "/cadastro-adicional", 
      state: {partner: dataPartner, operator: dataOperator, uId}
    });
    
  }

  function onBlurCep(ev) {
    const { value } = ev.target;
    setMessageErrorCep("");

    let cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          return setMessageErrorCep("O cep digitado não foi encontrado.");
        }
        setCountry("Brasil");
        setState(data.uf);
        setCity(data.localidade);
        setStreet(data.logradouro);
      })
      .catch(() => {
        setMessageErrorCep("Algo saiu errado, tente novamente mais tarde.");
      });
  }

  useEffect(() => {
    body.style.backgroundColor = "#10233f";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  // RESET PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
    body.style.overflow = "";
    setMessageErrorApi("");
    setHasEmptyInput(false);
    setMessageErrorCpf("");
    setMessageErrorTerms("");
  }, []);

  useEffect(() => {
    if (messageErrorCpf !== "") {
      window.scrollTo(0, 0);
    }
  }, [messageErrorCpf]);

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    const userLocalStorage = JSON.parse(localStorage.getItem("IvoclarUser"));

    if (userLocalStorage === null) {
      history.push("/");
    }
  }, [history]);

  return (
    <>
      <Header isShowLinks={false}/>
      <div className="container-customersInfo">
        <h1>Cadastre-se</h1>

        <p>
          Informe abaixo os dados referentes ao dentista que você deseja
          cadastrar.
        </p>

        <form
          onSubmit={handleSubmit}
          onChange={() => {
            setMessageErrorTerms("");
            setMessageErrorCep("");
            setMessageErrorApi("");
          }}
          className="main-content-customers"
        >
          <div className="area-input-customers">
            {messageErrorCpf && (
              <span style={{ color: "red", fontSize: "13px" }}>
                {messageErrorCpf}
              </span>
            )}
            <label>CPF:*</label>
            <input
              type="text"
              name="cpf"
              maxLength="14"
              placeholder="000.000.000-00"
              disabled={dataPartner?.cpf}
              style={{ color: dataPartner?.cpf ? '#FFF' : ""}}
              value={cpfMask(cpf)}
              onChange={(e) => {
                setCpf(e.target.value);
                setMessageErrorCpf("");
              }}
            />
            <label>Nome:*</label>
            <input
              type="text"
              name="name"
              placeholder="Nome"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <label>Sobrenome:*</label>
            <input
              type="text"
              name="lastName"
              placeholder="Sobrenome"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <label>CRO:*</label>
            <input
              type="text"
              name="lastName"
              placeholder="00000000000"
              value={cro}
              onChange={(e) => {
                setCro(e.target.value);
              }}
            />
            <label>Celular:*</label>
            <input
              type="tel"
              name="cellphone"
              placeholder="(00) 00000-0000"
              maxLength="15"
              value={cellPhone}
              onChange={(e) => {
                setCellPhone(cellPhoneMask(e.target.value));
              }}
            />
            <label>Telefone fixo:*</label>
            <input
              type="tel"
              name="phoneNumber"
              placeholder="(00) 0000-0000"
              maxLength="14"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(phoneMask(e.target.value));
              }}
            />

            <label>Email:*</label>
            <input
              type="text"
              name="email"
              placeholder="email@exemplo.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="area-input-customers">
            {messageErrorCep && (
              <span style={{ color: "red", fontSize: "13px" }}>
                {messageErrorCep}
              </span>
            )}
            <label>CEP:*</label>
            <input
              type="text"
              name="cep"
              maxLength="9"
              onBlur={onBlurCep}
              placeholder="00000-000"
              value={maskCep(cep)}
              onChange={(e) => {
                setCep(e.target.value);
              }}
            />
            <label>País:*</label>
            <input
              type="text"
              name="country"
              placeholder="Brasil"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />

            <label>Estado:*</label>
            <input
              type="Estado"
              name="country"
              placeholder="Estado"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />

            <label>Cidade:*</label>
            <input
              type="text"
              name="city"
              placeholder="Cidade"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />

            <label>Rua:*</label>
            <input
              type="text"
              name="street"
              placeholder="Rua"
              value={street}
              onChange={(e) => {
                setStreet(e.target.value);
              }}
            />
            <label>Número:*</label>
            <input
              type="text"
              name="number"
              placeholder="Número"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />

            <label>Complemento:</label>
            <input
              type="text"
              name="aditional"
              placeholder="Complemento"
              value={aditionalInformation}
              onChange={(e) => setAditionalInformation(e.target.value)}
            />
          </div>
        </form>

        <div className="area-checkboxs">
          <div style={{ marginBottom: "20px" }}>
            <label className="container-checkbox">
              <span className="text-checkbox">
                Li e aceito o Regulamento da promoção comercial.
              </span>
              <input
                defaultChecked
                type="checkbox"
                onClick={() => {
                  setIsRegulation((oldValue) => !oldValue);
                  setMessageErrorTerms("");
                }} 
                className="checked"
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label className="container-checkbox">
              <span className="text-checkbox">
                Li e aceito os Termos e Condições de Uso e Políticas de Privacidade da Ivoclar.
              </span>
              <input
                defaultChecked
                type="checkbox"
                onClick={() => {
                  setIsTerms((oldValue) => !oldValue);
                  setMessageErrorTerms("");
                }}
                className="checked"
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div style={{ marginBottom: "50px" }}>
            <label className="container-checkbox">
              <span className="text-checkbox">
                Aceito receber notificações por aplicativo, SMS e -mail.
              </span>
              <input
                defaultChecked
                type="checkbox"
                onClick={() => setIsNotification((oldValue) => !oldValue)}
                className="checked"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          
          <span style={{ color: "#FFFF", fontSize: "0.7rem"  }}>
            Ver {" "}
            <a
              style={{ color: "#fff" }}
              title="Políticas de Privacidade"
              target="_blank"
              rel="noreferrer"
              href={LINK_PDF}
            >
              Regulamento da promoção.
            </a>  
          </span>     

          <span style={{ color: '#FFFF', fontSize: "0.7rem" }}>
            Ver {" "}
            <a
            style={{ color: "#fff" }}
            title="Políticas de Privacidade"
            target="_blank"
            rel="noreferrer"
            href={LINK_PRIVACY_POLICY}
            >
              Termos e Condições de Uso e Políticas de Privacidade.
            </a>       
          </span>
        
        </div>

        {messageErrorTerms && (
          <span
            style={{
              color: "red",
              fontSize: "13px",
              marginBottom: "5px",
            }}
          >
            {messageErrorTerms}
          </span>
        )}

        {messageErrorApi && (
          <span
            style={{
              color: "red",
              fontSize: "13px",
              marginBottom: "5px",
            }}
          >
            {messageErrorApi}
          </span>
        )}

        <button
          disabled={!hasEmptyInput}
          className="button-customers"
          type="button"
          style={{
            backgroundColor: `${!hasEmptyInput ? "#b6e1f3" : "#42baef"}`,
            cursor: `${!hasEmptyInput ? "no-drop" : "pointer"}`,
          }}
          onClick={handleSubmit}
        >
          Próximo 
        </button>
      </div>
      <Footer />
    </>
  );
}

export default CustomersInfo;
