import React, { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { premios } from "../../constants/productsList";

import "./slideDoubts.css";

const LINK_SITE_TRIIBO = "https://www.ivoclar.triibo.com.br/";
const DUVIDAS = [
  {
    title: "1 - Quem pode participar da promoção?",
    text: "Todos os Dentistas e Técnicos em Prótese Dentária que possuam registro no CRO poderão aderir a promoção, mediante aceitação plena e irrestrita dos Termos e Condições da Promoção.",
  },
  {
    title: "2 - Como faço para participar?",
    text: "",
  },
  {
    title: "3 - Qual o período de participação?",
    text: "Período da promoção: 09/05/2022 a 30/11/2022 \n Período de participação: 09/05/2022 a 30/11/2022 \n Cupons acumulados neste período.",
  },
  {
    title: "4 - Não recebi os cupons, o que fazer?",
    text: "Caso não tenha recebido seus cupons, entre em contato com a Dental que fez a aquisição dos prêmios.",
  },
  {
    title: "5 - Quais produtos de presente posso solicitar?",
    text: "Uma vez creditada a bonificação de um IPS e.max, o Cliente poderá escolher a bonificação dentre os produtos e disponibilidade na Dental escolhida para resgate:",
  },
  {
    title: "6 - Quais produtos posso cadastrar?",
    text: "Serão promovidas todas as pastilhas IPS e.max da Ivoclar.",
  },
  {
    title: "7 - Quem é a Triibo?",
    text: "",
  },
  {
    title: "8 - Como resgatar o meu prêmio?",
    text: "O voucher de resgate de seu prêmio será enviado automaticamente para a carteira Triibo quando você atingir o número necessário de compras registradas. Para resgatá-lo, você deverá informar o código do mesmo em uma de nossas dentais.",
  },
  {
    title: "9 - Tenho outras dúvidas. Como posso entrar em contato?",
    text: "Qualquer dúvida, você pode entrar em contato pelo número +55 (11) 98548-1546.",
  },
];

function SlideDoubts() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    // RESETA O SCROLL DA LISTA DE PRODUTOS
    const resetScroll = document.querySelector("#product-list");
    resetScroll.scrollTop = 0;
  }, [expanded]);

  return (
    <div className="container-slade-doubts">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        <div className="carousel-inner">
          <div
            style={{ marginBottom: "20px" }}
            className="carousel-item active"
          >
            {/* PART 1 DO CARROSSEL  */}
            <section className="accordion">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[0].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[0].text}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[1].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Para participar, o Cliente deverá realizar sua inscrição por
                    meio do site:{" "}
                    <a
                      title="Triibo"
                      target="_blank"
                      rel="noreferrer"
                      href={LINK_SITE_TRIIBO}
                      style={{ color: "#000" }}
                    >
                      https://www.ivoclar.triibo.com.br
                    </a>
                    , sendo permitida uma única inscrição por Cliente (CNPJ ou
                    CPF).
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[2].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {DUVIDAS[2].text}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </section>
          </div>

          {/* PART 2 DO CARROSSEL */}
          <section style={{ marginBottom: "20px" }} className="carousel-item">
            <div className="accordion">
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[3].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[3].text}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[4].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    id="product-list"
                    style={{ maxHeight: "400px", overflow: "auto" }}
                  >
                    {DUVIDAS[4].text}
                    <br />
                    <br />
                    {premios.map((dental, index) => {
                      return (
                        <div key={index}>
                          <p>- {dental.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[5].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[5].text}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </section>

          {/* PART 3 DO CARROSSEL */}
          <section style={{ marginBottom: "20px" }} className="carousel-item">
            <div className="accordion">
              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[6].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    A Triibo é uma comunidade digital que disponibiliza diversos
                    mecanismos para unir você a lugares, oportunidades, diversão
                    e informação. A parceria Ivoclar e Triibo proporciona a
                    automação da promoção, acesso aos conteúdos exclusivos e
                    disponibiliza todas as vantagens de pertencer a rede Triibo.
                    Caso queira conhecer mais sobre a Triibo, acesse:{" "}
                    <a
                      title="Triibo"
                      target="_blank"
                      rel="noreferrer"
                      href="https://triibo.com.br/"
                      style={{ color: "#000" }}
                    >
                      https://triibo.com.br/
                    </a>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[7].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[7].text}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[8].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[8].text}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </section>
        </div>
      </div>

      {/* BOTOES DE CONTROLE DO CARROSSEL */}
      <div className="content-carousel-control">
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default SlideDoubts;
