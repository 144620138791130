import axios from "axios";
import { getChannelToken_v1 } from "../Apis/getChannelToken_v1";
import { configJson } from "../firebaseUtils";

export function getWallet_v1(userId, triiboId) {
  const uId = userId ? userId : triiboId;
  //OBTER INFORMAÇÕES DO USUÁRIO
  return new Promise((resolve, reject) => {
    getChannelToken_v1(uId)
      .then((resultTriiboHeader) => {
        // console.log("RESULTADO GET CHANNEL TOKEN ===>", resultTriiboHeader);
        axios
          .post(configJson.API_HOST_V1 + "getWallet_v1", {
            triiboHeader: resultTriiboHeader,
            triiboId: triiboId,
          })
          .then((result) => {
            const wallet = {
              coupons: result.data.success.wallet,
              totalPoints: result.data.success.totalPoints,
              triiboId: result.data.success.requestParams?.triiboId,
            };
            // console.log("RESUL_GET_WALLET: ", wallet);
            resolve(wallet);
          })
          .catch((error) => {
            console.log("ERROR_GET_WALLET: ", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("ERROR_GET_CHANNEL_TOKEN ===>", error);
        reject(error);
      });
  });
}
