import axios from "axios";
import { configJson } from "../firebaseUtils";
import { getChannelToken_v1UidNull } from "../Apis/getChannelToken_v1UidNull";

export function login(cellphone) {
  return new Promise((resolve, reject) => {
    localStorage.clear();
    getChannelToken_v1UidNull()
      .then((resultTriiboHeader) => {
        axios
          .post(configJson.API_HOST_V1 + "login", {
            triiboHeader: resultTriiboHeader,
            cellphone,
          })
          .then((result) => {
            // console.log("RESULT_LOGIN: ", result);
            resolve(result);
          })
          .catch((error) => {
            console.log("ERROR_LOGIN: ", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("TRIIBO_HEADER_ERROR_LOGIN: ", error);
        reject(error);
      });
  });
}

export default login;
