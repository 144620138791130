import axios from "axios";
import { configJson } from "../firebaseUtils";

export function apiOrder(itemId, sender, receiver) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        configJson.API_HOST + "api_order",
        {
          itemId,
          sender,
          receiver,
          requestDate: Date.now(),
          requestLat: -23.60805899726243,
          requestLon: -46.63697103031574,
          status: "requested",
          type: "validation",
        },
        {
          headers: { access_token: configJson.ACCESS_TOKEN },
        }
      )
      .then((result) => {
        // console.log("RESUL_API_ORDER ====>", result);
        resolve(result);
      })
      .catch((error) => {
        console.log("ERRO API ORDER ===>", error);
        reject(error);
      });
  });
}
