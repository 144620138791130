const dentais = [
  {
    name: "BIODENTE MATERIAIS ODONTOLOGICOS LTDA",
    email: "daiane.hoffmann@biodente.com.br",
  },
  {
    name: "COMPROX COMÉRCIO DE PROD ODONT",
    email: "vendas@comproxdental.com.br",
  },
  {
    name: "DENTAL GLOBO LTDA - ME",
    email: "gerenteclinico@dentalglobo.net",
  },
  {
    name: "DENTAL CREMER PROD ODONT S A",
    email: "eric.silva@dentalcremer.com.br",
  },
  {
    name: "DENTAL FOCUS COMÉRCIO DE PROD ODONT LTDA",
    email: "anderson@dentalfocus.com.br",
  },
  {
    name: "DENTAL FREE COMERCIO DE MATERIAIS ODONTO",
    email: "claudiney.valentim@dentalfree.com.br",
  },
  {
    name: "DENTAL ITA COMERCIO DE PRODUTOS E EQUIPA",
    email: "triibo@dentalita.com.br",
  },
  {
    name: "DENTAL MULLER COM PROD ODONTO LTDA",
    email: "paulo.lusa@dentalmuller.com.br",
  },
  {
    name: "DENTAL PAULISTA EQUIPAMENTOS EIRELI - ME",
    email: "marco@dentalpaulista.com.br",
  },
  {
    name: "R C MATERIAL ODONTOLOGICO LTDA ME",
    email: "dentalrc@dentalrc.com.br",
  },
  {
    name: "DENTAL S SEBASTIÃO LTDA ME",
    email: "diretoria@dentalss.com.br",
  },
  {
    name: "DENTAL SORRISO LTDA",
    email: "administrativo@dentalsorrisobh.com.br",
  },
  {
    name: "DENTAL TIRADENTES LTDA",
    email: "vanessa@dentaltiradentes.com.br",
  },
  {
    name: "EUNICE SOLUCÕES PROD ODONT",
    email: "fabiano@eunicesolucoes.com.br",
  },
  {
    name: "FERDINANDO MATOS DA SILVA FILHO EPP (Dental Mex)",
    email: "financeiro.dentalmex@hotmail.com",
  },
  {
    name: "H2T ODONTOLVILLE COMÉRCIO DE PROD",
    email: "andergg@gmail.com",
  },
  {
    name: "IVOCLAR VIVADENT LTDA",
    email: "bruno.coimbra@ivoclar.com",
  },
  {
    name: "JOAO REIS COM PROD ODONT LTDA-ME",
    email: "joaoreisdental@uol.com.br",
  },
  {
    name: "NACIONAL DENTALCOM E REP LTDA ME",
    email: "lojanacionaldental@outlook.com",
  },
  {
    name: "NOVA DENTAL PROGRESSO",
    email: "junior@dentalprogresso.com.br",
  },
  {
    name: "ODONTO VIX PROD ODONTO LTDA-ME",
    email: "administrativo@vixdental.com.br",
  },
  {
    name: "PATRICIA DE OLIVEIRA SILVA ME",
    email: "torres@dentalprotetic.com.br",
  },
  {
    name: "SAÚDE DOCTOR COMERCIO LTDA",
    email: "cesar@saudental.com",
  },
  {
    name: "SURYA DENTAL COM PROD ODONT HOSP LTDA",
    email: "igor.mattos@suryadental.com.br",
  },
  {
    name: "TRIIBO TESTE",
    email: "fahr.cassia@gmail.com",
  },
];

export { dentais };
