import axios from "axios";
import { configJson } from "../firebaseUtils";
import { getChannelToken_v1UidNull } from "../Apis/getChannelToken_v1UidNull";

export function authSMS_v1(cellPhone) {
  //OBTER INFORMAÇÕES DO USUÁRIO
  return new Promise((resolve, reject) => {
    getChannelToken_v1UidNull()
      .then((resultTriiboHeader) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);
        axios
          .post(configJson.API_HOST_V1 + "authSMS_v1", {
            triiboHeader: resultTriiboHeader,
            cellphone: cellPhone,
            platform: "ivoclar-site",
          })
          .then((result) => {
            // console.log("RESUL_AUTH_SMS ===>", result);

            if (result.data.success !== null) {
              resolve(result.data.success);
            } else {
              reject({ messageError: result.data.error.error });
            }
          })
          .catch((error) => {
            console.log("ERROR AUTH SMS ===>", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("ERROR GET CHANNEL TOKEN ===>", error);
        reject(error);
      });
  });
}
