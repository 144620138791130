import axios from "axios";
import { configJson } from "../firebaseUtils";
import { getChannelToken_v1UidNull } from "../Apis/getChannelToken_v1UidNull";

export function voucherSend_v1(templateId, triiboId) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1UidNull()
      .then((resultTriiboHeader) => {
        axios
          .post(
            configJson.API_HOST_V1 + "voucherSend_v1",
            {
              triiboHeader: resultTriiboHeader,
              clientID: triiboId,
              templateID: templateId,
              consumed: true,
            },
            {
              headers: { access_token: configJson.ACCESS_TOKEN },
            }
          )
          .then(async (result) => {
            // console.log("RESULT_VOUCHER_SEND: ", result);
            resolve(result);
          })
          .catch((error) => {
            console.log("ERROR_RESULT_VOUCHER_SEND: ", error);
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
        console.log("ERROR_GET_CHANNEL_TOKEN_GET_USER_INFO: ", error);
      });
  });
}
