import React, { useState, useEffect } from "react";
import { FaWallet } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { useHistory } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import imageNota from "../../assets/images/imageNota.png";
import { configJson } from "../../services/firebaseUtils";

import LoadingPageExternal from "../../components/LoadingExternalPage";

import ModalInformation from "../../components/ModalInformation";
import voucherConsumer from "../../services/Apis/voucherConsumer";
import { voucherLogAddProduct } from "../../services/Apis/voucherLog";
import uploadImage from "../../services/uploadImage";
import { getUserInfo_v1 } from "../../services/Apis/getUserInfo_v1";
import getCampaign from "../../services/Apis/getCampaign";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { maskVoucher } from "../../utils/masks";

import "./addProduct.css";

const CAMPAIGN_ID = configJson.CAMPAIGN_ID;  
const body = document.querySelector("body");

function AddProduct() {
  const [taxNumber, setTaxNumber] = useState("");
  const [couponNumbers, setCouponNumbers] = useState(["I02"]);
  const [messageErrorVoucher, setMessageErrorVoucher] = useState("");
  const [showModalInformation, setShowModalInformation] = useState(false);

  const [imagePath, setImagePath] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [pastaStorage, setPastaStorage] = useState("");
  const [newImageName, setNewImageName] = useState("");
  const [showExemplo, setShowExemplo] = useState(false);

  const [messageErrorImg, setMessageErrorImg] = useState("");
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading02, setLoading02] = useState(false)
  const [uIdUrl, setUid] = useState(null);
  const [originUrl, setOriginUrl] = useState("");
  const [userCellphone, setUserCellphone] = useState("");
  const [apiError, setApiError] = useState(false);

  const [hasPartnerIdIvoclar, setHasPartnerIdIvoclar] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setApiError(false);
    setHasPartnerIdIvoclar(false);
    setShowModalInformation(false);
    setLoading(true);
    setLoading02(false);
    setMessageError("");
    setMessageErrorVoucher("");
    setMessageErrorImg("");
    setImagePath(null);
    body.style.backgroundColor = "#10233f";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  function getUserInfo(clearCellPhone) {
    const userInfo = {
      contactList: [
        {
          type: "cellPhone",
          value: clearCellPhone,
        },
      ],
    };

    getUserInfo_v1(userInfo)
      .then(async(resultUser) => {
        let isPartnerIdIvoclar = false;
          
          // VERIFICA SE O USUÁRIO PARTICIPA DA CAMPANHA ATUAL.
          const resultCampaign = await getCampaign(resultUser.uId, CAMPAIGN_ID, "1");
          if (resultCampaign.data.status) {  
            isPartnerIdIvoclar = true; 
          }
          
          setLoading(false);

          if (resultUser && isPartnerIdIvoclar) {
            setHasPartnerIdIvoclar(true);
          }
      })
      .catch(() => {
        setMessageError("Algo saiu errado, tente novamente mais tarde.");
        setLoading(false);
        setApiError(true);
      });
  }

  function toggleInformation() {
    setShowModalInformation(!showModalInformation);

    // QUANDO O MODAL ESTIVER ABERTO EVITA SCROLAR O CONTEÚDO DA PÁGINA
    body.style.overflow = showModalInformation ? "" : "hidden";
  }

  function handleFile(event) {
    if (event.target.files[0]) {
      const image = event.target.files[0];

      if (image.size > 5242880) {
        setMessageErrorImg("Envie uma imagem com no máximo 5mb");
        return;
      }

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "application/pdf"
      ) {
        setMessageErrorImg("");

        const pastaStorage = "ivoclar_documents";
        const newImageName = `${uIdUrl}_${Date.now()}`;

        setNewImage(image);
        setImagePath(`ivoclar_documents/${newImageName}`);
        setPastaStorage(pastaStorage);
        setNewImageName(newImageName);
      } else {
        setMessageErrorImg(
          "Formato de imagem inválido, envie uma imagem no formato Png, Jpeg ou Pdf"
        );
      }
    }
  }

  async function uploadImageCode() {
    try {
      await uploadImage(pastaStorage, newImage, newImageName);

    } catch {
      setMessageErrorImg("Algo saiu errado, tente novamente mais tarde.");

    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    // APAGA O TOKEN COM O UID DO ULTIMO USUÁRIO PESQUISADO PARA EVITAR ERROS 
    localStorage.removeItem("ivoclarToken")
    setMessageError("");
    setMessageErrorVoucher("");
    setMessageErrorImg("");

    if (taxNumber.trim().length === 0) {
      window.scrollTo(0, 0);
      setMessageError(
        "Adicione o número da Nota Fiscal - NF-e para continuar."
      );
      return;
    }

    if (imagePath === null) {
      setMessageErrorImg(
        "Adicione a foto da Nota Fiscal - NF-e para continuar."
      );
      return;
    }

    setLoading02(true);

    voucherConsumer(couponNumbers, taxNumber, uIdUrl)
      .then((result) => {
        setLoading02(false);

        const invalidCodes = Object.keys(result).filter(
          (voucherId) => result[voucherId] === null
        );

        const vouchersIds = Object.keys(result).filter(
          (voucherId) => result[voucherId] !== null
        );

        if (invalidCodes.length === 0) {
          toggleInformation();
        }

        if (vouchersIds.length >= 1) {
          uploadImageCode();
          voucherLogAddProduct(uIdUrl, taxNumber, imagePath, vouchersIds);
        }

        couponNumbers.forEach((voucherNumber) => {
          if (result[voucherNumber] === null) {
            setMessageErrorVoucher("Código inválido.");
            // REMOVE OS INPUTS QUE DEU CERTO
            setCouponNumbers([...invalidCodes]);
          }
        });
      })
      .catch(() => {
        setLoading02(false);
        setMessageError("Algo saiu errado, tente novamente mais tarde.");
      });
  }

  function addInput() {
    setMessageErrorVoucher("");
    setCouponNumbers([...couponNumbers, "I02"]);
  }

  function removeInput(position) {
    setMessageErrorVoucher("");
    setCouponNumbers([
      ...couponNumbers.filter((_, index) => index !== position),
    ]);
  }

  function addNewCouponNumbers(event, index) {
    const newNumberVoucher = maskVoucher(event.target.value);

    couponNumbers[index] = newNumberVoucher;
    setCouponNumbers([...couponNumbers]);
  }

  useEffect(() => {
    // PEGA A ORIGIN DA URL
    const referrer = document.referrer;
    setOriginUrl(referrer);

    //Buscando variáveis da URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uId = urlParams.get("uId");
    const triiboIdUrl = urlParams.get("triiboId");

    if (
      uId === "" ||
      uId === null ||
      triiboIdUrl === "" ||
      triiboIdUrl === null
    ) {
      history.push("/");
    } else {
      const clearCellPhone = `+${triiboIdUrl.slice(0, 14).trim()}`;
      setUid(uId);
      setUserCellphone(clearCellPhone);
      getUserInfo(clearCellPhone);
    }
  }, [history]);

  if (loading) {
    return <LoadingPageExternal />;
  } else if (apiError) {
    return (
      <div style={{ height: "100vh", display: "flex", alignItems: "center" }}>
        <h1 style={{ color: "#fff", textAlign: "center" }}>
          Ocorreu um erro. Por favor tente novamente.
        </h1>
      </div>
    );
  } else {
    return (
      <>
      <Header isShowLinks={false} />
      <div className="container-addProduct">
        <main>
          {!hasPartnerIdIvoclar && !loading ? (
            <div
              style={{
                display: "flex",
                maxWidth: "850px",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 20px",
                marginTop: "30%",
              }}
            >
              <h2 style={{ color: "#fff", textAlign: "center" }}>
                Você ainda não tem cadastro na promoção IPS e.max.
              </h2>
              <button
                className="file-img-add"
                type="button"
                onClick={() => history.push("/cadastrar")}
              >
                Cadastre-se agora
              </button>
            </div>
          ) : (
            <>
              <h1>Registre sua compra</h1>

              <p>
                Informe o número da Nota Fiscal de sua compra, anexe uma foto da
                mesma e digite os códigos dos cupons que você recebeu com ela.
                Seus cupons serão armazenados no site/app Triibo e poderão ser
                encontrados acessando o ícone de sua carteira
                {""}
                <FaWallet style={{ marginLeft: "5px" }} />.
              </p>

              <form
                onSubmit={handleSubmit}
                onChange={() => {
                  setMessageError("");
                  setMessageErrorVoucher("");
                }}
                className="form-content"
              >
                {messageError && (
                  <label style={{ color: "red", fontSize: "14px" }}>
                    {messageError}
                  </label>
                )}
                <label>
                  Informe o número da Nota Fiscal{" "}
                  <strong
                    onClick={() => setShowExemplo(!showExemplo)}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  >
                    (Onde encontrar o número?)
                  </strong>{" "}
                  :
                </label>

                {showExemplo && (
                  <img
                    style={{ marginBottom: "10px", width: "100%" }}
                    src={imageNota}
                    alt="Nota"
                  />
                )}

                <input
                  type="text"
                  name="taxNumber"
                  placeholder="00000000"
                  disabled={loading02}
                  value={taxNumber}
                  onChange={(e) => setTaxNumber(e.target.value)}
                  style={{ marginBottom: "40px" }}
                />
                {messageErrorImg && (
                  <label style={{ color: "red", fontSize: "14px" }}>
                    {messageErrorImg}
                  </label>
                )}

                <label>Envie uma foto da Nota Fiscal:</label>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <label
                    style={{ fontSize: "inherit", marginBottom: "inherit" }}
                    className="file-img-add"
                    htmlFor="arquivo"
                  >
                    Enviar imagem
                  </label>
                  {newImage && (
                    <strong
                      style={{
                        color: "#fff",
                        marginLeft: "15px",
                        maxWidth: "195px",
                      }}
                    >
                      {newImage.name}
                    </strong>
                  )}
                </div>
                <input
                  disabled={loading02}
                  name="arquivo"
                  id="arquivo"
                  type="file"
                  accept="application/pdf, image/*"
                  onChange={handleFile}
                />
                {couponNumbers.map((coupon, index) => {
                  return (
                    <div
                      key={String(index)}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {messageErrorVoucher !== "" && (
                        <label style={{ fontSize: "14px", color: "red" }}>
                          {messageErrorVoucher}
                        </label>
                      )}
                      <label>Código do cupom: {index + 1}</label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            borderBottomRightRadius: `${
                              index !== 0 ? "0px" : "7px"
                            }`,
                            borderTopRightRadius: `${
                              index !== 0 ? "0px" : "7px"
                            }`,
                          }}
                          id={`input-${index + 1}`}
                          type="text"
                          name="couponNumbers"
                          maxLength="17"
                          placeholder="XXX-XXX-000000-0"
                          disabled={loading02}
                          value={coupon.toUpperCase()}
                          onChange={(event) =>
                            addNewCouponNumbers(event, index)
                          }
                        />
                        {index > 0 && (
                          <div
                            onClick={() => {!loading02 && removeInput(index)}}
                            className="input-add"
                          >
                            <BsTrash color="#fff" />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

                {loading02 ? (
                  <CircularProgress
                    size={20}
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      color: "white",
                      marginTop: "20px",
                    }}
                  />
                ) : (
                  <>
                    <button type="button" onClick={addInput}>
                      Adicionar mais cupons
                    </button>
                    <button
                      onClick={handleSubmit}
                      disabled={couponNumbers.includes("")}
                      type="submit"
                      style={{
                        cursor: `${
                          couponNumbers.includes("") ? "no-drop" : "pointer"
                        }`,
                      }}
                    >
                      Registrar compra
                    </button>
                  </>
                )}
              </form>
            </>
          )}
        </main>
        {showModalInformation && (
          <ModalInformation
            close={toggleInformation}
            setCouponNumbers={setCouponNumbers}
            setImagePath={setImagePath}
            setNewImage={setNewImage}
            originUrl={originUrl}
            setTaxNumber={setTaxNumber}
            cellPhone={userCellphone}
          />
        )}

        {hasPartnerIdIvoclar && !loading && <Footer />}
      </div>
      </>
    );
  }
}

export default AddProduct;
