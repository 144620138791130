import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";

import { setUserInfo_v1 } from "../../services/Apis/setUserInfo_v1";
import setAdditionalInfos from "../../services/Apis/setAdditionalInfos";

import { useLocation, useHistory } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import { CircularProgress } from "@material-ui/core";

import {configJson} from "../../services/firebaseUtils"

import "./additionalInfo.css";

const option = ["Dekema", "Dentsply-Sirona", "EDG", "Ivoclar Vivadent", "Kota", "Outros"];
const areasWork = [
  "Cad/Cam",
  "Digital",
  "Metal free",
  "Metalocerâmica",
  "Prótese total removível",
  "Protocolo acrílico",
  "PPR",
  "Prótese sobre implante",
]

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 10,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: "#fff",
    },
    backgroundColor: "#fff",
  },
}))(InputBase);

const CAMPAIGN_ID = configJson.CAMPAIGN_ID;

const body = document.querySelector("body");
function FormAddicionalInfo() {
	const [ovenModel, setOvenModel] = useState("Dekema");
  const [workAreas, setWorkAreas] = useState([]);
	const [have3dPrinter, setHave3dPrinter] = useState(false)
  const [messageErrorWorkAreas, setMessageWorkAreas] = useState("");
  const [messageErrorApi, setMessageErrorApi] = useState("");
	const [loading, setLoading] = useState(false);

  // DADOS DO FORMULÁRIO ANTERIOR
  const location = useLocation();
  const history = useHistory();

	useEffect(() => {
		body.style.backgroundColor = "#10233f";
		window.scrollTo(0, 0);
    setMessageWorkAreas("");

		return () => {
				body.style.backgroundColor = "#fff";
		};
	}, []);

	function handleSubmit(e) {
		e.preventDefault();
		setLoading(true);
    setMessageWorkAreas("");
    setMessageErrorApi("");

    if (workAreas.length === 0) {
      setMessageWorkAreas("Selecione uma area");
		  setLoading(true);

      return;
    }

    const data = {
      ...location.state.partner.partnerList,
      ovenModel,
      workAreas,
      have3dPrinter
    }

    const operatorData = location.state.operator;
    const uId = location.state.uId;
    const participated = JSON.parse(localStorage.getItem("ivoclar_alreadyParticipated"))
    
    if (participated.alreadyParticipated) {
      addDataAdditionalInfo(data, uId);
      return;
    }

    setUserInfo_v1(uId, operatorData)
    .then(() => {
      addDataAdditionalInfo(data, uId);
    })
    .catch((error) => {
      console.log("error api set_user_info: ", error);
      setMessageErrorApi("Algo saiu errado, tente novamente mais tarde.");
      setLoading(false);
    });
    
	}
  
  async function addDataAdditionalInfo(data, uId) {
    const recipient = data.entityType === "Profissional (CPF)" ? "prf" : "lab"

    try {
      await setAdditionalInfos(uId, CAMPAIGN_ID, recipient, data)
      // LIMPA O LOCAL_STORAGE
      localStorage.clear();
      
      setLoading(false);
      history.push("/informe");
    }catch {
      console.log("Error ao adicionar valores no partner...");
      setMessageErrorApi("Algo saiu errado, tente novamente mais tarde.");
      setLoading(false);
    }
  }

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    const userLocalStorage = JSON.parse(localStorage.getItem("IvoclarUser"));

    if (userLocalStorage === null) {
      history.push("/");
    }
  }, [history]);

	return(
		<>
			<Header isShowLinks={false}/>
			<div className="container-form-additional-info">
				<div className="content-text-form-adicional-info">
					<h1>Cadastre-se</h1>

					<p>
						Informe abaixo os dados referentes ao laboratório que você deseja
						cadastrar.
					</p>
				</div>	

				<form 
					onSubmit={handleSubmit}
					className="content-form-additional-info"
				>

				<label>Qual o forno que você utiliza?*</label>
          <NativeSelect
            id="item"
            value={ovenModel}
            onChange={(e) => setOvenModel(e.target.value)}
            input={<BootstrapInput />}
          >
            {option.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </NativeSelect>
			
          {messageErrorWorkAreas && (
            <span style={{ color: "red", fontSize: "13px" }}>
              {messageErrorWorkAreas}
            </span>
          )}
          <label id="select-work" style={{ marginTop: "20px" }}>Com quais áreas trabalha?*</label>
          <Select
            htmlFor="select-work"
            id="select-work"
            multiple
            value={workAreas}
            onChange={(e) => setWorkAreas(e.target.value)}
            input={<BootstrapInput />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{ variant: 'menu' }}
          >
            {areasWork.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={workAreas.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
           
          </Select>
      	
          <label style={{marginTop: "20px"}}>Possúi impressora 3d?</label>
          <div className="content-radio-form-additional-info">
            <div className="custom-radio-form-additional-info">
              <input checked={have3dPrinter} id="radio-yes" onChange={() => {}} onClick={() => setHave3dPrinter(true)} name="machine" type="radio" value="yes"/>
              <label htmlFor="radio-yes">Sim</label>
            </div>

            <div className="custom-radio-form-additional-info">
              <input checked={!have3dPrinter} id="radio-no" onChange={() => {}}  onClick={() => setHave3dPrinter(false)}  name="machine" value="no" type="radio"/>
              <label htmlFor="radio-no">Não</label>
            </div>  
          </div>


          {messageErrorApi && (
            <span style={{ color: "red", fontSize: "13px", marginBottom: '20px' }}>
              {messageErrorApi}
            </span>
          )}

					<button
            disabled={loading || workAreas.length === 0}
            className="button-customers"
            type="submit"
            style={{
              backgroundColor: `${workAreas.length === 0 ? "#b6e1f3" : "#42baef"}`,
              cursor: `${workAreas.length === 0 ? "no-drop" : "pointer"}`,
            }}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "#fff" }} />
            ) : (
              "Confirmar informações"
            )}
          </button>

				</form>

				<div 
					style={{ width: "100%"}}
					>
          <Footer />
        </div>
			</div>
		</>
		
	);
}

export default FormAddicionalInfo;