/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
import "./header.css";

const LINK_IVOCLAR = "https://www.ivoclar.com/pt_br";

// PDF DE REGULAMENTO DA PROMOÇÃO FICA TRIIBO-HOMOL/FIREBASE/STORAGE/IVOCLAR
const LINK_PDF = "https://firebasestorage.googleapis.com/v0/b/project-4616920480246266429.appspot.com/o/ivoclar%2FRegulamento_v4.pdf?alt=media&token=88b6c432-2511-4228-bb9b-0743d0f41dd4";

function Header({ setShowModalLogin, isShowLinks, setNextPage }) { 
  const [isActive, setIsActive] = useState(false);
  const hasActiveMenu = isActive ? "nav-header active" : "nav-header";

  const history = useHistory();

  function toggleMenu() {
    setIsActive((oldValue) => !oldValue);
  }

  return (
    <>
    <header className="container-header">
      <a
        style={{ display: 'flex' }}
        title="site-ivoclar"
        target="_blank"
        rel="noreferrer"
        href={LINK_IVOCLAR}
      >
        <img src={logo} alt="Logo Ivoclar" className="logo-header" />
      </a>

        <nav className={hasActiveMenu}>
          <button onClick={toggleMenu} id="btn-mobile">
            <span id="hamburger"></span>
          </button>
          <ul className="menu-header">
             <li>
              <a
                onClick={() => {
                  history.push('/');
                  setIsActive((oldValue) => !oldValue)
                }}
                className="header-button"
                >
                Inicio
              </a>
            </li>
            {isShowLinks && (
            <li>
              <a
                onClick={() => setIsActive((oldValue) => !oldValue)}
                title="promoção"
                href="#promocao"
                >
                Sobre a promoção
              </a>
            </li>
            )}
           
            <li>
              <a
                title="carreira"
                target="_blank"
                rel="noreferrer"
                href={LINK_PDF}
              >
                Regulamento
              </a>
            </li>
            
          {isShowLinks && (
            <li>
              <a
                onClick={() => setIsActive((oldValue) => !oldValue)}
                title="App triibo"
                href="#app"
              >
                Baixe o app
              </a>
            </li>
          )}
          {isShowLinks && (
            <li>
              <a
                href="#login"
                onClick={() => {
                  setShowModalLogin(true);
                  setIsActive((oldValue) => !oldValue);
                  setNextPage("triibo-web");
                }}
                className="header-button"
              >
                Login
              </a>
            </li>
          )}
          </ul>
        </nav>
    </header>
    <div className="margin-botton-header"/>
    </>
  );
}

export default Header;
