import { mask } from "remask";

function cellPhoneMask(value) {
  value = value.replace(/\D/g, "");
  let cellPhone = "";
  if (value.length === 0) {
    cellPhone = "";
  } else if (value.length >= 1 && value.length <= 2) {
    cellPhone = "(" + value.substr(0, 2);
  } else if (value.length > 2 && value.length <= 7) {
    cellPhone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5);
  } else {
    cellPhone =
      "(" +
      value.substr(0, 2) +
      ") " +
      value.substr(2, 5) +
      "-" +
      value.substr(7, 4);
  }
  return cellPhone;
}

function phoneMask(v) {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
}

function cpfMask(value) {
  value = value.replace(/\D/g, "");

  let cpf = "";

  if (value.length === 0) {
    cpf = "";
  } else if (value.length >= 1 && value.length <= 3) {
    cpf = value.substr(0, 3);
  } else if (value.length > 3 && value.length <= 6) {
    cpf = value.substr(0, 3) + "." + value.substr(3, 3);
  } else if (value.length > 6 && value.length <= 9) {
    cpf =
      value.substr(0, 3) + "." + value.substr(3, 3) + "." + value.substr(6, 3);
  } else {
    cpf =
      value.substr(0, 3) +
      "." +
      value.substr(3, 3) +
      "." +
      value.substr(6, 3) +
      "-" +
      value.substr(9, 2);
  }

  return cpf;
}

function maskCnpj(v) {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
  return v;
}

function maskCep(value) {
  value = value.replace(/\D/g, "");
  let cep = "";
  if (value.length === 0) {
    cep = "";
  } else if (value.length > 0 && value.length < 6) {
    cep = value.substr(0, 5);
  } else if (value.length > 5 && value.length < 9) {
    cep = value.substr(0, 5) + "-" + value.substr(5, 3);
  } else {
    cep = value.substr(0, 5) + "-" + value.substr(5, 3);
  }
  return cep;
}

function maskDate(value) {
  value = value.replace(/\D/g, "");

  let date = "";

  if (value.length === 0) {
    date = "";
  } else if (value.length > 0 && value.length < 3) {
    date = value.substr(0, 2);
  } else if (value.length > 2 && value.length < 5) {
    date = value.substr(0, 2) + "/" + value.substr(2, 2);
  } else {
    date =
      value.substr(0, 2) + "/" + value.substr(2, 2) + "/" + value.substr(4, 4);
  }

  return date;
}

function maskReal(val) {
  let v = val.replace(/\D/g, "");
  v = (v / 100).toFixed(2) + "";
  v = v.replace(".", ",");
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
  return v;
}

function maskVoucher(value) {
  return mask(value, ["A99-AAA-9999999-9"]);
}

export {
  cellPhoneMask,
  maskCep,
  cpfMask,
  maskDate,
  maskReal,
  maskCnpj,
  phoneMask,
  maskVoucher,
};
