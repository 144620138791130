import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FiX } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";

import sendEmail from "../../services/Apis/sendEmail";
import { voucherLogRequest } from "../../services/Apis/voucherLog";

import { configJson } from "../../services/firebaseUtils";
import { encrypt } from "../../utils/helpers";

const CRYPTO_KEY = configJson.CRYPTO_KEY;
const CAMPAIGN_ID = configJson.CAMPAIGN_ID;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalRequestPrize({
  close,
  open,
  uId,
  user,
  triiboId,
  dental,
  product,
  productAndCode,
  emailDental,
  amountPrizes,
  arrayPrize,
}) {
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [originUrl, setOriginUrl] = useState("");
  const [messageErrorVoucher, setMessageErrorVoucher] = useState("");
  const [cellphoneValidation, setCellphoneValidation] = useState("");

  const clearCellPhone = triiboId.slice(0, 14);

  useEffect(() => {
    // PEGA A ORIGIN DA URL
    const referrer = document.referrer;
    setOriginUrl(referrer);

    setMessageError("");
    setMessageSuccess("");
    setLoading(false);
  }, []);

  function resetPage() {
    setMessageError("");
    setMessageSuccess("");
    setMessageErrorVoucher("");
    setLoading(false);
  }

  function createTemplateEmail() {
    let telefone = "";
    let email = "";
    let tipo = "";
    let nomeEmpresa = "";
    let endereco = "";

    user.contactList?.forEach((item) => {
      if (item.type === "cellPhone") {
        telefone = item.value;
      }
      
      if (item.type === "email") {
        email = item.value;
      }
    });
    
    user.partnerList?.forEach((item) => {
      if (item.partnerName === "IvoClar") {
        item.additionalInfo.forEach((partner) => {
          if (partner.campaignId === CAMPAIGN_ID) {
            if (partner.entityType === "Profissional (CPF)") {
              tipo = partner.entityType;
              nomeEmpresa = user.name;
              endereco = partner.addressList[0];
            } else {
              tipo = partner.entityType;
              nomeEmpresa = partner.corporateName;
              endereco = partner.addressList[0];
            }
          }
        });
      }
    });

    const textEmail = `Olá,
    ${""}
      Nome: ${user.name}
      Telefone: ${telefone}
      E-mail: ${email}
      ${""}
      Está solicitando o resgate ${
        amountPrizes <= 1 ? "do seguinte item:" : "dos seguintes items:"
      }
      ${""}
      ${productAndCode}
      ${""}
      Em sua organização.
      ${""}
      Em nome de:
      Nome: ${nomeEmpresa}
      Tipo: ${tipo}
      Endereço: ${endereco?.city} - ${endereco?.state}, ${endereco?.street} - ${
      endereco?.number
    }
      ${""}
      Para efetuar a entrega utilize o App Triibo Indentificando-se
      com um telefone celular autorizado.
    `;

    return textEmail;
  }

  async function sendEmailDental() {
    setLoading(true);

    const templateEmail = createTemplateEmail();

    try {
      const result = await voucherLogRequest(uId, dental, emailDental, arrayPrize, CAMPAIGN_ID);
    
      if (!result.voucherLog?.campaignId && !result.voucherLog?.status) {
        const voucherConsumed = result.voucherLog?.notAllowed
        const length = voucherConsumed.length
        const templateMessage = `${length > 1 ? 
          "Os seguintes items já foram solicitados: " : 
          "O seguinte item já foi solicitado: "} ${voucherConsumed.join(', ')}.`
        setLoading(false);
        setMessageSuccess("");
        setMessageErrorVoucher(templateMessage);
        return;
      }

      await sendEmail(emailDental, templateEmail, uId);
      setLoading(false);
      setMessageSuccess("ok");
    }catch {
      setLoading(false);
      setMessageError("Algo saiu errado, tente novamente mais tarde.");
    }
  }

  function goToTriibo() {
    // PASSAR PARA LOGAR
    const LINK_WEB_APP_LOGADO = `${
      configJson.LINK_SITE_TRIIBO
    }/entrance/?userId=${encodeURIComponent(
      encrypt(clearCellPhone, CRYPTO_KEY)
    )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;

    window.location.href = LINK_WEB_APP_LOGADO;
  }

  useEffect(() => {
    user.contactList?.forEach((item) => {
      if (item.type === "cellPhone") {
        setCellphoneValidation(item.value);
      }
    });
  },[user]);
  
    return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {close(false); resetPage()}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{ color: "white", backgroundColor: "#10233f" }}
          id="alert-dialog-slide-title"
        >
          Confirme o prêmio
          <FiX
            onClick={() => {close(false); resetPage()}}
            size={25}
            color={"#fff"}
            style={{ position: "absolute", right: "10", cursor: "pointer" }}
          />
        </DialogTitle>
        {messageSuccess === "" && (
          <DialogContent style={{ marginTop: "10px" }}>
            <DialogContentText id="alert-dialog-slide-description">
              <strong>Dental escolhida: </strong>
              {dental}
              <br />
              <br />
              <strong>{amountPrizes > 1 ? "Prêmios: " : "Prêmio: "}</strong>
              {product}

              {messageErrorVoucher !== "" && (
              <label
                style={{
                  color: "red",
                  marginTop: "10px",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                {messageErrorVoucher}
              </label>
            )}
            </DialogContentText>
          </DialogContent>
        )}
        {loading ? (
          <DialogActions style={{ justifyContent: "center" }}>
            <CircularProgress size={20} style={{ color: "#42baef" }} />
          </DialogActions>
        ) : (
          <DialogActions
            style={{ minWidth: "300px", minHeight: "130px", padding: "0 20px" }}
          >
            {messageError !== "" && (
              <label
                style={{
                  color: "red",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                {messageError}
              </label>
            )}
            {messageSuccess === "ok" && messageError === "" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  Seu pedido foi enviado com sucesso. Aguarde o contato da
                  Dental escolhida.
                </h2>
                <div
                  style={{
                    maxWidth: `${originUrl.length === 0 ? "300px" : "140px"}`,
                  }}
                >
                  {originUrl.length === 0 ? (
                    <strong
                      style={{
                        marginTop: "15px",
                        color: "#646566",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Retorne para o App Triibo.
                    </strong>
                  ) : (
                    <Button
                      onClick={goToTriibo}
                      style={{
                        color: "#42baef",
                        fontWeight: "bold",
                        width: "180px",
                      }}
                    >
                      Voltar a Triibo
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => {close(false); resetPage();}}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                >
                  Modificar
                </Button>
                <Button
                  onClick={() => 
                    {cellphoneValidation === triiboId.split("@")[0] 
                      ? sendEmailDental() 
                      : setMessageError("TriiboId diverge do número de contato, entre em contato com a triibo.")}}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                >
                  Confirmar
                </Button>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
