import React from "react";
import { configJson } from "../../services/firebaseUtils";
import { Button } from "@material-ui/core";

import './messageRequestPrize.css';

function MessageRequestPrize({errorApi,originUrl}) {
    return(
        <div className="container--message-request-prize">
            {errorApi ? (
              <h1>Ocorreu um erro. Por favor tente novamente.</h1>
            ) : (
              <>
                <h2 className="text-message--message-request-prize">
                  Você ainda não registrou quantidade suficiente de compras para
                  solicitar seu prêmio. Consulte seus registros de compra na
                  carteira do app Triibo.
                </h2>
              </>
            )}
            {originUrl.length === 0 ? (
              <strong className="subtitle--message-request-prize">
                Retorne para o App Triibo.
              </strong>
            ) : (
              <a
                href={configJson.LINK_VOLTAR_TRIIBO}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
									style={{
										background: "#42baef",
										fontWeight: "bold",
										color: "#FFF",
									}}
                >
                  Voltar para o Triibo
                </Button>
              </a>
            )}
          </div>
    )
}

export default MessageRequestPrize;