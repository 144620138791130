import React, { useEffect, useState, useContext } from "react";
import { FiX } from "react-icons/fi";
import { cellPhoneMask, cpfMask } from "../../utils/masks";
import { ValidateCpf } from "../../utils/helpers";
import { CircularProgress } from "@material-ui/core";

import { UserContext } from "../../contexts/user";

import "./modalLogin.css";

const body = document.querySelector("body");

function ModalLogin({ close, nextPage, setNextPage, data }) {
  
  const {
    checkUserWithCellPhone,
    sendCodeToApi,
    setCellPhoneUser,
    setOperatorCpf,
    setCodeSms,
    loading,
    setLoading,
    setIsSendPhone,
    hasPartnerList,
    isSendPhone,
    setMessageErroCellPhone,
    messageErroCellPhone,
    setMessageErroCode,
    messageErroCode,
    message,
    setMessage,
    hasRegistration,
    setHasRegistration,
    isRegisteredUser,
    setIsRegisteredUser,
  } = useContext(UserContext);

  const [cellPhone, setCellPhone] = useState("");
  const [cpf, setCpf] = useState('');
  const [messageErrorCpf, setMessageErrorCpf] = useState("");
  const [codeNumber, setCondeNumber] = useState("");

  useEffect(() => {
    setMessage("");
    setCondeNumber("");
    setMessageErroCellPhone("");
    setMessageErroCode("");
    setMessage("");
    setIsRegisteredUser(true);
    setIsSendPhone(false);
    setLoading(false);
    setMessageErrorCpf("");

    return () => {
      // QUANDO O MODAL FECHAR LIBERAR O HABILITA O SCROLL
      body.style.overflow = "";
    };
  }, [
    setIsRegisteredUser,
    setIsSendPhone,
    setMessage,
    setMessageErroCellPhone,
    setMessageErroCode,
    setLoading,
  ]);

  async function checkUser(event) {
    event.preventDefault();
    const isCellPhoneValid = cellPhone.length === 15;

    // VERIFICA CPF
    if (nextPage !== "triibo-web" && !ValidateCpf(cpf.replace(/\D/g, ""))) {
      setLoading(false);
      return setMessageErrorCpf(
        "CPF inválido. Revise o campo de CPF e tente novamente."
      );
    }

    if (isCellPhoneValid) {
      setOperatorCpf(cpf.replace(/\D/g, ""));

      checkUserWithCellPhone(nextPage, data);
    } else {
      setLoading(false);
      setIsSendPhone(false);
      setMessageErroCellPhone(
        "Número de telefone inválido, digite um número com 9 digitos."
      );
    }
  }

  return (
    <div className="modal-login">
      <div className="container-login">
        <div className="header-modal-login">
          { !isSendPhone ?
            <span>Identifique-se</span>
            :
            <span>Código de autenticação</span>
          }
          <button className="close" 
            onClick={() => {
              close(); 
              // LIMPA O LOCAL_STORAGE
              localStorage.removeItem("IvoclarUser");
            }}>
            <FiX size={25} color={"#fff"} />
          </button>
        </div>

        <form className="form-login" onSubmit={checkUser}>
          {!isSendPhone ? (
            <>
              <br/>
              {/* CONTEÚDO PARA ADICIONAR TELEFONE */}
              {messageErroCellPhone && (
                <label style={{ color: "red" }}>{messageErroCellPhone}</label>
              )}
              {message && <label style={{ color: "red" }}>{message}</label>}

              {
                nextPage === "triibo-web" ? 
                  <span className="title-modal-login">Informe seu número de celular</span>
                :
                <>
                  <span className="title-modal-login">Informe os seus dados abaixo</span>

                  <span>As informações a seguir <strong>serão utilizadas para acessar a conta e cadastro dos produtos</strong>, 
                    e não necessariamente precisam ser os do laboratório.
                  </span>
                </>
              }

              {messageErrorCpf.length > 0 && nextPage !== "triibo-web" && (
              <span style={{ color: "red", fontSize: "13px" }}>
                {messageErrorCpf}
              </span>
            )}

            {
              nextPage !== "triibo-web" && 
              <>
                <label className="label-input-modal-login">CPF:</label>
                <input
                  value={cpf}
                  onChange={(event) => {
                    setCpf(cpfMask(event.target.value));
                    setMessageErrorCpf("");
                  }}
                  autoFocus
                  type="tel"
                  name="cpf"
                  placeholder="000.000.000-00"
                  maxLength="14"
                  autoComplete="off"
                />
              </>
            }

              <label className="label-input-modal-login">Celular:</label>
              <input
                value={cellPhone}
                onChange={(event) => {
                  setCellPhone(cellPhoneMask(event.target.value));
                  setCellPhoneUser(
                    `+55${event.target.value.replace(/\D/g, "")}`
                  );
                  setMessageErroCellPhone("");
                  setIsRegisteredUser(true);
                  setMessage("");
                }}
                type="tel"
                name="phone"
                placeholder="(00) 00000-0000"
                maxLength="15"
                autoComplete="off"
              />

            { nextPage === "triibo-web" ?
              <button
                className="button-login"
                disabled={ cellPhone.replace(/\D/g, "").length < 11 || loading}
                type="submit"
                style={{
                  cursor: `${
                    cellPhone.replace(/\D/g, "").length !== 11
                      ? "no-drop"
                      : "pointer"
                  }`,
                }}
                onClick={checkUser}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  "Próximo"
                )}
              </button>
              :
              <button
                className="button-login"
                disabled={ cellPhone.replace(/\D/g, "").length < 11 || cpf.replace(/\D/g, "").length < 11 || loading}
                type="submit"
                style={{
                  cursor: `${
                    cellPhone.replace(/\D/g, "").length !== 11
                    || cpf.replace(/\D/g, "").length !== 11
                      ? "no-drop"
                      : "pointer"
                  }`,
                }}
                onClick={checkUser}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  "Próximo"
                )}
              </button>
            }
              
              {!hasPartnerList && isRegisteredUser && nextPage === "triibo-web" && messageErroCellPhone !== "" &&
                <button
                  type="button"
                  className="button-nav-login"
                  onClick={() => {
                    setMessage(""); 
                    setMessageErroCellPhone("");
                    setNextPage("/cadastrar");
                  }}
                  >
                  Ainda não participa da campanha? <span style={{ textDecoration: "underline", fontSize: "inherit" }}>Inscreva-se</span>
                </button>
              }

              {!isRegisteredUser && nextPage === "triibo-web" && (
                <button
                  type="button"
                  className="button-nav-login"
                  onClick={() => {
                    setMessage(""); 
                    setNextPage("/cadastrar");
                  }}
                >
                  Não tem uma conta? Registre-se
                </button>
              )}
            </>
          ) : (
            <>
              {/* CONTEÚDO PARA ADICIONAR SMS */}
              {messageErroCode && (
                <label style={{ color: "red" }}>{messageErroCode}</label>
              )}
              <span  className="title-modal-login">
                Informe o código recebido em seu celular.
              </span>

              <label className="label-input-modal-login">Código:</label>
              <input
                value={codeNumber}
                onChange={(event) => {
                  setCondeNumber(event.target.value);
                  setCodeSms(event.target.value);
                  setMessageErroCode("");
                }}
                type="tel"
                name="code_number"
                placeholder="000000"
                maxLength="6"
                autoComplete="off"
              />

              <button
                disabled={codeNumber === "" || loading}
                style={{
                  cursor: `${codeNumber === "" ? "no-drop" : "pointer"}`,
                }}
                type="submit"
                className="button-login"
                onClick={() => sendCodeToApi(codeNumber)}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  "Próximo"
                )}
              </button>

              {!hasRegistration && !loading && (
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#646566",
                  }}
                  onClick={() => {
                    setIsSendPhone(false);
                    setCondeNumber("");
                    setMessageErroCode("");
                    setHasRegistration(false);
                  }}
                >
                  Ainda não recebeu o código?{" "}
                  <span
                    style={{ color: "#646566", textDecoration: "underline" }}
                  >
                    Solicite novamente
                  </span>
                </span>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default ModalLogin;
